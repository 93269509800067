<template>
  <div class="admin_addUser">
    <div class="title">创建用户 | 控制台</div>
    <div>
      手机号
    </div>
    <br>
    <div style="width: 30%">
      <el-input v-model="username" placeholder="请输入手机号" />
    </div>
    <br>
    <div>
      <el-button type="primary" @click="addUser">添加</el-button>
    </div>


  </div>
</template>

<script>
import {ElNotification} from "element-plus";

const telReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
export default {
  name: "admin-add-user",
  data(){
    return{
      username:'',
    }
  },
  methods:{
    addUser(){

      if(telReg.test(this.username)){
        this.$requestServers.post("manager/admin/user/add",
            {
              'phone': this.username,
            }
        ).then(res=>{
          console.log(res)
            if(res.data === 200){
              ElNotification({
                title: '成功',
                message: '成功添加一位用户',
                type: 'success',
              })

            }else if(res.data === 501){
              ElNotification({
                title: '错误',
                message: '手机号已存在',
                type: 'error',
              })
            }

        })
      }else{
        ElNotification({
          title: '提示',
          message: '请输入正确的手机号',
          type: 'warning',
        })
      }

    }
  }
}
</script>

<style scoped>
.admin_addUser{
  width: 90%;
  height: 100%;
  padding: 5%;
}
.admin_addUser .title {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 50px;
}
</style>