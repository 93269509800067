<template>
  <van-nav-bar
      title="购物车"
      left-text="返回"
      left-arrow
      fixed
      placeholder="true"
      @click-left="onClickLeft"
  >
  </van-nav-bar>


  <div class="cart_body">

    <van-skeleton :row="3" :loading="this.pageLoading">
      <template #template>
        <div :style="{ width: '100%' }">
          <div :style="{ marginTop:'10px'}" v-for="items in [1,2,3,4,5,]">
            <van-skeleton-paragraph row-width="60%" />
            <van-skeleton-paragraph />
            <van-skeleton-paragraph />
            <van-skeleton-paragraph />
          </div>
        </div>
      </template>

      <van-pull-refresh v-model="this.loading" @refresh="onRefresh">

        <van-swipe-cell v-for="items in cartList">
          <div class="cb_list">
            <div class="cm_option">
              <!--          <van-checkbox ></van-checkbox>-->
              <input class="checked_list" :id="'cb_'+items.id" type="checkbox" @click="choice(items)">

            </div>
            <div class="cl_img"></div>
            <div class="cl_msg">
              <div class="cm_name">
                <span>{{ items.clothes.name }}</span>
              </div>

              <div class="cl_date">
                <span> 取件日期：</span>
                <div class="cl_d_body"  @click="showCaledar(items.date,items.id)">
                <span class="dmd_date">
                  {{
                    items.date.split("-")[0] + '年' + items.date.split("-")[1] + '月' + items.date.split("-")[2] + '日'
                  }}
                <van-icon name="edit" size="15px"/>
                </span>
                </div>
              </div>
              <div class="cl_number">
                <b>
             <span class="price">
              ￥
            </span>
                  <span class="price">
              {{
                      ((items.clothes.price * (items.clothes.discount === -1 ? 1 : (items.clothes.discount / 10))) * items.number).toFixed(2)
                    }}
            </span>
                </b>
                <span>
            <b>
              <van-stepper v-model="items.number" min="1" max="99" @blur="changeNumber(items.id,items.number)"
                           @click="changeNumber(items.id,items.number)" integer/>
            </b>
          </span>
              </div>
            </div>


          </div>
          <template #right>
            <div class="cb_list_rightPop" @click="deleteFromCart(items)">
              删除
            </div>
          </template>
        </van-swipe-cell>
      </van-pull-refresh>

    </van-skeleton>



  </div>

  <div style="height: 8%;"></div>
  <div class="submit_ft">
    <div class="sf_ck">
      <input id="selectAll" type="checkbox" @click="selectAll" :checked="this.selectAllChecked">全选
    </div>
    <div class="sf_price">
      <span> 合计:</span>
      <span>￥</span>
      <span>{{ this.totalPrice.toFixed(2) }}</span>
    </div>
    <div class="submit_button">
      <input type="button" value="预约" @click="pay">
    </div>
  </div>

  <van-calendar
      v-model:show="this.calShow"
      :default-date="this.startDate"
      @confirm="onConfirmDate"
  />
  <van-empty
      image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png"
      image-size="80"
      description="空空如也~"
      v-show="this.cartList.length <= 0 || this.cartList === []"
  />
  <template v-if="this.showCheckOrder">
    <checkorder
      ref="checkorder"
      :cart-list="this.selectList"
      :total-price="this.totalPrice"
      :oid="this.orderid"
      :isdirect="false"
      @closeTab='getClose'
    >
    </checkorder>
  </template>
</template>

<script>
import * as $ from "jquery";
import checkorder from "@/components/checkorder";
import {closeToast, showFailToast, showLoadingToast, showSuccessToast, showToast} from "vant";

export default {
  name: "carts",
  components: {
    checkorder,
  },
  data() {
    return {
      loading: false,
      checked: [],
      calShow: false,
      //原始日期
      startDate: null,
      selectAllChecked: false,
      //总价
      totalPrice: 0,
      //正在聚焦的商品id
      focusId: '',
      //显示确认订单
      showCheckOrder: false,
      //订单号
      orderid: '',
      //页面加载状态
      pageLoading:true,
      cartList: [],

      selectList: [],
    }
  },

  mounted() {
    this.initCart()
  },
  methods: {
    idMatch() {
      if (localStorage.getItem("elysova-laundry-id") === '' || localStorage.getItem("elysova-laundry-id") === null) {
        window.open('/#/user/login', '_self')
      }
      //验证id和uuid一致性
      this.$requestServers.post("user/idMatch",
          {
            'id': localStorage.getItem('elysova-laundry-id'),
            'uuid': localStorage.getItem('elysova-chief-id')

          }).then(res => {
        if (!res) {
          window.open('/#/user/login', '_self')
        }
      })
    },

    onRefresh() {
      setTimeout(() => {
        this.initCart()
        showToast('刷新成功');
        this.loading = false;

      }, 1000);
    },
    initCart() {
      this.idMatch();
      this.getCarts();


    },
    changeNumber(id, number) {
      showLoadingToast({forbidClick: true});

      return new Promise((resolve) => {
        setTimeout(() => {
          closeToast();


          this.$requestServers.post("clothes/updateNumber",
              {
                'id': id,
                'number': number

              }).then(res => {
            if (res.data === 200) {
              resolve(true)
              this.totalPrice = 0
              for (let i = 0; i < this.cartList.length; i++) {
                if ($('#cb_' + this.cartList[i].id).is(':checked')) {
                  this.priceCalculator(true, this.cartList[i])
                  this.selectList.push(this.cartList[i])
                }

              }
            } else {
              resolve(false);
              showFailToast(res.msg);
            }
          })


        }, 500);
      });
    },
    //点击单选框
    choice(items) {
      let allFlag = true;
      let checked = document.getElementsByClassName("checked_list")
      let selectAll = document.getElementById("selectAll")
      for (let i = 0; i < checked.length; i++) {
        if (!checked[i].checked) {
          selectAll.checked = false;
          allFlag = false
          break;
        }
      }
      if (allFlag) {
        selectAll.checked = true;
      }

      if ($('#cb_' + items.id).is(':checked')) {
        this.selectList.push(items)
        this.priceCalculator(true, items)
      } else {
        this.priceCalculator(false, items)
        this.selectList = this.selectList.filter(function (e) {
          return e !== items
        })
      }

      console.log(this.selectList)
    },
    //全选
    selectAll() {
      this.totalPrice = 0
      let checked = document.getElementsByClassName("checked_list")
      for (let i = 0; i < checked.length; i++) {
        checked[i].checked = !this.selectAllChecked
      }
      if (!this.selectAllChecked) {
        this.selectList = []
        for (let i = 0; i < this.cartList.length; i++) {
          this.priceCalculator(true, this.cartList[i])
          this.selectList.push(this.cartList[i])
        }
      } else {
        for (let i = 0; i < this.cartList.length; i++) {
          this.totalPrice = 0
          this.totalPrice.toFixed(2)
          this.selectList = []
        }
      }
      this.selectAllChecked = !this.selectAllChecked
    },

    //价格计算器
    priceCalculator(isAdd, items) {
      let price = items.clothes.price
      let discount = items.clothes.discount
      let number = items.number
      let p = ((price * (discount === -1 ? 1 : (discount / 10))) * number)
      isAdd ? this.totalPrice += p : this.totalPrice -= p
      this.totalPrice.toFixed(2)
    },


    pay() {

      if (this.selectList.length <= 0) {
        showToast('还未选择服务项');
      } else {
        this.showCheckOrder = true
      }
    },


    getCarts() {
      this.$requestServers.post("clothes/getCarts",
          {
            'id': localStorage.getItem('elysova-laundry-id'),
          }).then(res => {
        // console.log(res)
        this.cartList = res
        this.pageLoading = false
      })
    },


    deleteFromCart(item) {
      this.$requestServers.post("clothes/delCart",
          {
            'id': item.id,
          }).then(() => {
        this.cartList = this.cartList.filter(function (e) {
          return e !== item
        })
        showSuccessToast('删除成功');
      })
    },


    onClickLeft() {
      history.back()
    },

    showCaledar(date, id) {
      this.calShow = !this.calShow
      this.startDate = new Date(date)
      this.focusId = id
    },

    onConfirmDate(value) {
      // 年
      let y = value.getFullYear()
      // 月
      let m = value.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      // 日
      let d = value.getDate()
      d = d < 10 ? ('0' + d) : d

      let dateTime1 = y + '-' + m + '-' + d

      this.$requestServers.post("clothes/updateDate",
          {
            'id': this.focusId,
            'date': dateTime1
          }).then(res => {
        if (res.data === 200) {
          showSuccessToast('日期修改成功');
          this.calShow = false;
          for (let i = 0; i < this.cartList.length; i++) {
            if (this.cartList[i].id === this.focusId) {
              this.cartList[i].date = dateTime1
              break;
            }
          }
        } else {
          showFailToast(res.msg);
          this.calShow = false;
        }
      })

    },
    getClose(value) {

      setTimeout(() => {
        this.showCheckOrder = value
      }, 300)

    },

  }
}
</script>

<style scoped>
.cart_body {
  width: 100%;
}

.cb_list {
  margin: 5px auto;
  border-radius: 15px;
  width: 95%;
  box-shadow:inset 0 0 10px 0 rgba(201, 201, 201, 0.2);
  border: 2px solid #dedede;
  display: flex;
  justify-content: left;
  align-items: center;
}

.cl_img {
  width: 30%;
  height: 100%;
  background-color: #fafafa;
}

.cl_msg {
  width: 60%;
  padding: 4% 2%;
}

.cl_msg .cm_name {
  font-size: 15px;

}

.cl_date span:nth-child(1){
  display: block;
  margin-top: 10px;
  font-size: 10px;
}

.cl_date input {
  width: 50%;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  -webkit-appearance:none;
}

.cl_d_body{
  margin-top: 5%;
}

.dmd_date {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  font-weight: 900;
  color: #737373;
  margin-top: 5%;
  width: 120px;
}


.cl_number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
}

.cl_number .price {
  font-size: 14px;
  color: #ff5e17;
}

.cl_number .price:nth-child(1) {

}

.cl_number .price:nth-child(2) {
  font-size: 20px;
}

.cl_number span:nth-child(1) {

}

.cm_option {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.cb_list_rightPop {
  color: #fafafa;
  font-weight: bolder;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 100%;
  margin-right: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: #c40000;
}

.submit_ft {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: rgb(250, 250, 250);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit_button {
  width: 40%;
  height: 100%;
}

.submit_button input {
  width: 100%;
  height: 100%;
  border: 2px solid #0078f1;
  background-color: #0078f1;
  font-weight: 100;
  font-size: 15px;
  color: #dedede;
  outline: none;
  -webkit-appearance:none;
}

.sf_ck {
  margin-left: 10px;
  font-size: 12px;
  font-weight: lighter;
}

.sf_price {
  display: flex;
  align-items: center;
}

.sf_price span:nth-child(1) {
  font-size: 12px;
  font-weight: lighter;
}

.sf_price span:nth-child(2) {
  color: #ff5e17;
}

.sf_price span:nth-child(3) {
  color: #ff5e17;
  font-size: 25px;
  font-weight: bolder;
}
</style>