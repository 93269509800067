<template>
  <van-swipe-cell>
    <div class="order_list" :class="'state'+this.msg.state" @click="this.orderDetail = true" >
      <div class="order_list_body">
        <div class="left">
          <div class="ol_items">
            <span>取件时间：</span>
            <span><b>{{ msg.date.split('-')[0] + '年' +msg.date.split('-')[1] + '月' +msg.date.split('-')[2] + '日' }}</b></span>
          </div>
          <br>
          <div class="ol_items">
            <span>{{ msg.address.split('%')[0] + ' ' + msg.address.split('%')[1] }}</span><br>
            <span><b>{{ msg.address.split('%')[2] + '&nbsp;&nbsp;' + msg.address.split('%')[3] }}</b></span>
          </div>
        </div>
        <div class="right">

        </div>
      </div>
      <div class="order_state">
        {{ this.orderState }}
      </div>
    </div>

    <template #right>
      <div class="cb_list_rightPop cancel" v-if="this.msg.state === 0" @click="cancelOrder()">
        取消订单
      </div>
      <div class="cb_list_rightPop confirm" v-if="this.msg.state === 5" @click="checkDelivery()">
        确认收货
      </div>
    </template>
  </van-swipe-cell>

  <template v-if="this.orderDetail">
    <order-detail
        :msg="msg"
        @closeTab="closeOrderDetail"
    />
  </template>


</template>

<script>
import OrderDetail from "@/components/OrderDetail";
import {showConfirmDialog, showFailToast, showSuccessToast} from "vant";
import * as $ from "jquery";

export default {
  name: "OrderList",
  props: ['msg'],
  data() {
    return {
      orderState: '网络错误',
      showAlert: false,

      orderDetail:false,
    }
  },
  components:{
    OrderDetail
  },
  mounted() {
    this.getOrderState();
  },
  methods: {
    cancelOrder() {
      showConfirmDialog({
        title: '您正在取消订单',
        message:
            '确定取消吗？',
      })
          .then(() => {
            //取消订单

            this.$requestServers.post("user/order/cancelOrder",
                {
                  'orderid':this.msg.orderid,
                  'id':localStorage.getItem('elysova-laundry-id'),
                  'uuid':localStorage.getItem('elysova-chief-id'),
                }
            ).then(res => {

              if(res>0){
                showSuccessToast('取消成功');
                location.reload()
              }else{
                showFailToast('未知错误:(');
              }

            })

          })
          .catch(() => {
            // on cancel
          });
    },

    checkDelivery() {
      showConfirmDialog({
        title: '您正在确认收货',
        message:
            '确定收货吗？',
      })
          .then(() => {
            //取消订单
            this.$requestServers.post("user/order/checkDelivery",
                {
                  'orderid':this.msg.orderid,
                  'id':localStorage.getItem('elysova-laundry-id'),
                  'uuid':localStorage.getItem('elysova-chief-id'),
                }
            ).then(res => {

              if(res>0){
                showSuccessToast('收货成功，订单关闭');
                location.reload()
              }else{
                showFailToast('未知错误:(');
              }

            })

          })
          .catch(() => {
            // on cancel
          });
    },
    getOrderState() {
      switch (this.msg.state) {
        case 0:
          this.orderState = '未接单';
          break;
        case 1:
          this.orderState = '已接单';
          break;
        case 2:
          this.orderState = '取货中';
          break;
        case 3:
          this.orderState = '正在进行';
          break;
        case 4:
          this.orderState = '即将送回';
          break;
        case 5:
          this.orderState = '正在送回';
          break;
        case 6:
          this.orderState = '已完成';
          break;
        case 10:
          this.orderState = '已取消';
          break;
        case 11:
          this.orderState = '已被退回';
          break;
        default:
          this.orderState = '未知错误';
          break;

      }
    },

    closeOrderDetail(value){
      setTimeout(()=>{
        this.orderDetail = value;
      },300)

    }
  }
}
</script>

<style scoped>
.order_list {
  margin: 10px auto;
  width: 90%;
  padding-bottom: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  overflow: hidden;
}

.order_state {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  font-weight: bolder;
}

.order_list_body {
  position: relative;
  top: 4px;
  margin: 0 auto;
  width: 88%;
  padding: 5%;
  color: #1c1c1c;
  font-weight: normal;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.order_list .left {
  width: 75%;
}
.order_list .right {
  width: 25%;
}

.order_list .ol_items {

}

.order_list .ol_items span:nth-child(1) {
  font-size: 14px;
}

.order_list .ol_items span:nth-child(2) {
  font-size: 18px;
}

.cb_list_rightPop {
  color: #fafafa;
  font-weight: bolder;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 100%;
  margin-right: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;

}
.cb_list_rightPop.cancel{
  background-color: #c40000;
}
.cb_list_rightPop.confirm{
  background-color: #0078f1;
}
/*未接单*/
.state0 {
  background-color: #c40000;
  color: #fafafa;

}

/*已接单*/
.state1 {
  background-color: #008cff;
  color: #fafafa;
}

/*取货中*/
.state2 {
  background-color: #8207b6;
  color: #fafafa;
}

/*正在进行*/
.state3 {
  background-color: #20ad00;
  color: #fafafa;
}

/*即将返回*/
.state4 {
  background-color: #ffd80b;
  color: #1c1c1c;
}

/*正在送回*/
.state5 {
  background-color: #ff690b;
  color: #fafafa;
}

/*已完成*/
.state6 {
  background-color: #ffffff;
  color: #1c1c1c;
}

/*已取消*/
.state10 {
  background-color: #525252;
  color: #fafafa;
}

/*已被退回*/
.state11 {
  background-color: #560000;
  color: #fafafa;
}


</style>