import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '@/views/Home'
import Carts from '@/views/carts'
import login from "@/views/user/login";
import order from "@/views/user/order";
import sentry from "@/views/admin/shared/sentry";
import adminLogin from "@/views/admin/admin-login";
import orderQP from "@/views/admin/mobile/orderQP";
import redirectTest from "@/views/admin/shared/redirectTest";
import viewControl from "@/views/admin/view-control";
import searchOrder from "@/views/admin/mobile/search-order-m";
import orderProcess from '@/views/admin/mobile/order-process'

import adminPcHello from '@/views/admin/pc/admin-pc-hello'
import adminPc from '@/views/admin/pc/admin-pc'
import adminUserManager from '@/views/admin/pc/admin-user-manager'
import adminAddUser from '@/views/admin/pc/admin-add-user'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '商城 | Elysova Tech',
    }
  },  {
    path: '/store/carts',
    name: 'Carts',
    component: Carts,
    meta: {
      title: '购物车 | Elysova Tech',
    }
  },  {
    path: '/user/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录 | Elysova Tech',
    }
  },  {
    path: '/user/order',
    name: 'order',
    component: order,
    meta: {
      title: '订单 | Elysova Tech',
    }
  },  {
    path: '/security/sentry',
    name: 'sentry',
    component: sentry,
    meta: {
      title: 'Elysova Sentry',
    }
  },  {
    path: '/security/login',
    name: 'adminLogin',
    component: adminLogin,
    meta: {
      title: 'Login | Elysova Sentry',
    }
  },  {
    path: '/private/quickprocess',
    name: 'orderQP',
    component: orderQP,
    meta: {
      title: '订单快处',
    }
  },  {
    path: '/test',
    name: 'redirectTest',
    component: redirectTest,
    meta: {
      title: '测试',
    }
  },  {
    path: '/private/pad',
    name: 'pad',
    component: viewControl,
    meta: {
      title: '管理员面板',
    }
  },  {
    path: '/private/mobile/searchOrder',
    name: 'searchOrder',
    component: searchOrder,
    meta: {
      title: '搜索订单',
    }
  },  {
    path: '/private/mobile/orderProcess',
    name: 'orderProcess',
    component: orderProcess,
    meta: {
      title: '订单过程处理',
    }
  },  {
    path: '/private/pad/pc/main',
    name: 'adminPc',
    component: adminPc,
    meta: {
      title: '管理员面板',
    },
    children: [
      {
        path: '/private/pc/hello',
        name: 'admin-pc-hello',
        component: adminPcHello
      }, {
        path: '/private/pc/manage/user',
        name: 'admin-user-manager',
        component: adminUserManager
      },{
        path: '/private/pc/manage/adduser',
        name: 'admin-add-user',
        component: adminAddUser
      },




    ]
  }
]

/*const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});*/

const router = createRouter({
  // base:'/flameartfront/',
  history: createWebHashHistory(process.env.BASE_URL),
  //history: createWebHistory(),
  routes
})
router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});
export default router
