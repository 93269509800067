<template>
  <admin-nav/>

  <div class="order_process">
    <div class="order_process_nav">
      <van-sidebar v-model="switchValue" @change="switchState">
        <van-sidebar-item title="待接单" :badge="this.orderTypeNum[0] === 0 ? '' : this.orderTypeNum[0]"/>
        <van-sidebar-item title="待前往" :badge="this.orderTypeNum[1]=== 0 ? '' : this.orderTypeNum[1]"/>
        <van-sidebar-item title="待取件" :badge="this.orderTypeNum[2]=== 0 ? '' : this.orderTypeNum[2]"/>
        <van-sidebar-item title="正在进行" :badge="this.orderTypeNum[3]=== 0 ? '' : this.orderTypeNum[3]"/>
        <van-sidebar-item title="待取回" :badge="this.orderTypeNum[4]=== 0 ? '' : this.orderTypeNum[4]"/>
        <van-sidebar-item title="待返件" :badge="this.orderTypeNum[5]=== 0 ? '' : this.orderTypeNum[5]"/>
      </van-sidebar>
    </div>

    <div class="order_process_body">
      <div class="op_list" v-for="items in orderlist" v-show="items.state === this.showStateFlag">
        <div class="op_id">
          ID：{{ items.orderid }}
        </div>
        <div>
          <span>地址：</span><br>
          <span>{{ items.address.split('%')[0] + ' ' + items.address.split('%')[1] + ' ' }}</span>
          <span><b>{{ items.address.split('%')[2] + '&nbsp;&nbsp;' + items.address.split('%')[3] }}</b></span>
        </div>

        <br>
        <div>
          联系方式：<b>{{ items.phone }}</b>
        </div>
        <br>
        <div class="op_btn" v-if="items.state === 0">
          <span><van-button type="danger" @click="sendBackOrder(items.orderid)">退回</van-button></span>
          <span><van-button type="success" @click="receiving(items.orderid)">接单</van-button></span>
        </div>
        <div class="op_btn" v-if="items.state === 1">
          <span><van-button type="danger" @click="sendBackOrder(items.orderid)">退回</van-button></span>
          <span><van-button type="primary" @click="collect(items.orderid)">前往取件</van-button></span>
        </div>
        <div class="op_btn" v-if="items.state === 2">
          <span><van-button type="danger" @click="sendBackOrder(items.orderid)">退回</van-button></span>
          <span><van-button type="success" @click="comfirmCollect(items.orderid)">确认取件</van-button></span>
        </div>
        <div class="op_btn" v-if="items.state === 3">
          <span><van-button type="success" @click="cleaned(items.orderid)">完成洗衣</van-button></span>
        </div>
        <div class="op_btn" v-if="items.state === 4">
          <span><van-button type="primary" @click="toSend(items.orderid)">前往送回</van-button></span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import adminNav from "@/components/admin/Admin-Nav";
import {showConfirmDialog, showToast} from "vant";

export default {
  name: "order-process",
  components: {
    adminNav
  },
  data() {
    return {
      switchValue: 0,
      showStateFlag: 0,
      orderlist: [],
      orderTypeNum: [0, 0, 0, 0, 0, 0]
    }
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      this.$requestServers.get('/manager/admin/order/notComplete').then(res => {
        this.pooledData(res.data)
      })
    },
    switchState(value) {
      let choice = -1;
      switch (value) {
          //待接单
        case 0:
          choice = 0;
          break;
          //待取件
        case 1:
          choice = 1;
          break;
          //待前往
        case 2:
          choice = 2;
          break;
          //正在进行
        case 3:
          choice = 3;
          break;
        case 4:
          choice = 4;
          break;
          //待反件
        case 5:
          choice = 5;
          break;
        default :
          choice = -1;
          break;
      }
      this.showStateFlag = choice;
    },


    receiving(id) {
      this.changeOrderState(id, 1, '接单成功')
    },
    collect(id) {
      showConfirmDialog({
        title: '提示 | 取件',
        message:
            '确认前往取件吗',
      })
          .then(() => {
            this.changeOrderState(id, 2, '确认前往取件')
          })
          .catch(() => {
            // on cancel
          });
    },

    comfirmCollect(id) {
      showConfirmDialog({
        title: '提示 | 取件',
        message:
            '确认取到件了吗',
      })
          .then(() => {
            this.changeOrderState(id, 3, '确认取件')
          })
          .catch(() => {
            // on cancel
          });
    },

    cleaned(id) {
      showConfirmDialog({
        title: '提示 | 完成洗衣',
        message:
            '确认洗衣店完成洗衣了吗',
      })
          .then(() => {
            this.changeOrderState(id, 4, '成功确认')
          })
          .catch(() => {
            // on cancel
          });
    },

    toSend(id) {
      this.changeOrderState(id, 5, '状态变更为：正在送回')
    },


    changeOrderState(id, state, successMsg) {
      this.$requestServers.post("manager/admin/order/updateState",
          {
            'uuid': id,
            'state': state,
          }
      ).then(res => {
        if (res.data === 200) {

          // this.orderlist = this.orderlist.filter(function (e) {
          //   return e.orderid !== id
          // })
          this.orderTypeNum[state-1]--;
          this.orderTypeNum[state]++;
          for (let i = 0; i < this.orderlist.length; i++) {
            if (this.orderlist[i].orderid === id) {
              this.orderlist[i].state = state;
              break;
            }
          }

          showToast(successMsg);

        } else {
          showToast('未知错误');
        }
      })
    },

    sendBackOrder(id) {
      showConfirmDialog({
        title: '敏感操作 | 退单',
        message:
            '确定退回该订单吗',
      })
          .then(() => {
            this.$requestServers.post("manager/admin/order/updateState",
                {
                  'uuid': id,
                  'state': 11,
                }
            ).then(res => {
              if (res.data === 200) {
                this.orderlist = this.orderlist.filter(function (e) {
                  return e.orderid !== id
                })
                showToast('已退回');
              } else {
                showToast('未知错误');
              }
            })

          })
          .catch(() => {
            // on cancel
          });
    },


    calculateNumber(state){
      this.orderTypeNum[state]++
    },


    /**
     * 订单列表预处理
     * @param list
     */
    pooledData(list) {
      //清空列表
      this.orderlist = []
      for (let i = 0; i < list.length; i++) {
        //是第一个数据
        if (i === 0) {
          //创建一个空结点
          this.orderlist.push(
              {
                orderid: list[i].orderid,
                date: list[i].datetime,
                orderdate: list[i].orderdate,
                address: list[i].address,
                state: list[i].state,
                phone: list[i].user.phone,
                msg: []
              }
          )
          this.calculateNumber(list[i].state)
          //将第一个数据加入列表
          this.orderlist[0].msg.push(list[i])

        }
        //不是第一个数据
        else {
          //是否进行插入操作flag
          let isInsert = false;
          //循环次数等于orderlist当前长度
          for (let j = 0; j < this.orderlist.length; j++) {
            //当订单号与当前orderlist第 j 块 orderid相同
            if (list[i].orderid === this.orderlist[j].orderid) {
              //将该条记录添加进第 j 块 数据中
              this.orderlist[j].msg.push(list[i])
              isInsert = true;
              break;
            }
          }
          //如果未进行插入，即没有orderid与其匹配
          //创建一个新的订单区域
          if (!isInsert) {
            //创建一个空结点
            this.orderlist.push(
                {
                  orderid: list[i].orderid,
                  date: list[i].datetime,
                  orderdate: list[i].orderdate,
                  address: list[i].address,
                  state: list[i].state,
                  phone: list[i].user.phone,
                  msg: []
                }
            )
            this.calculateNumber(list[i].state)
            //将数据加入列表
            this.orderlist[this.orderlist.length - 1].msg.push(list[i])
          }

        }
      }
    },
    initState(value) {
      switch (value) {
          //接单
        case 0:
          return '未接单'
          //取货
        case 1:
          return '已接单'
          //正在进行
        case 2:
          return '取货中'
          //即将送回
        case 3:
          return '正在进行'
          //正在送回
        case 4:
          return '即将送回'
          //退回
        case 5:
          return '正在送回'
        case 6:
          return '已完成'
        case 10:
          return '已取消'
        case 11:
          return '已退回'
        default :
          return '未知'
      }
    }


  }
}
</script>

<style scoped>
:root:root {
  --van-sidebar-width: 100%;
}

.order_process {
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.order_process_nav {
  width: 20%;
  height: 100vh;
  background-color: #f7f8fa;
  float: left;
}

.order_process_body {
  height: 100%;
  overflow-y: scroll;
}

.op_list {
  padding: 5%;
  width: 90%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;
}

.op_btn span {
  margin: 0 5px;
}
</style>