<template>
  <van-nav-bar title="V 0.8.3.5_alpha 开发者预览" left-text="返回" left-arrow>
    <template #left>
      <van-icon name="wap-nav" size="2rem" @click="this.showMenu = true"/>
    </template>
    <template #right>
      <van-icon name="completed" size="2rem" @click="toOrder"/>
    </template>
  </van-nav-bar>
  <van-notice-bar
      left-icon="volume-o"
      text="今日天气晴朗"
  />

  <van-skeleton :row="3" :loading="this.pageLoading">
    <template #template>
      <div :style="{ width: '100%' }">
        <div :style="{ marginTop:'10px'}" v-for="items in [1,2,3,4,5,]">
          <van-skeleton-paragraph row-width="60%"/>
          <van-skeleton-paragraph/>
          <van-skeleton-paragraph/>
          <van-skeleton-paragraph/>
        </div>
      </div>
    </template>
    <div class="home_body">
      <div class="commodity_card no-select pointer" v-for="items in this.commdity" @click="showLab(items)"
           :class="items.discount===-1?'':'discount_card'">
        <div class="commodity_card_img">
          <img src="https://www.elysova.com/resources/images/icon.png" alt="">

        </div>
        <div class="commodity_card_msg">
          <div>
            <span>{{ items.name }}</span>
          </div>
          <div class="ccm_price">
          <span
              :class="items.discount === -1 ? '' : 'color_discount'">￥{{
              (items.price * (items.discount === -1 ? 1 : (items.discount / 10))).toFixed(2)
            }}</span>
            <span v-if="items.discount !== -1"><del>￥{{ items.price }}</del> </span>
            <span v-if="items.discount !== -1"><b>-{{ (10 - items.discount) * 10 }}%</b></span>
          </div>
        </div>
      </div>
    </div>
  </van-skeleton>


  <van-popup
      v-model:show="this.showMenu"
      position="left"
      :style="{ width: '80%', height: '100%' }"
  >
    <main-menu/>
  </van-popup>

  <template v-if="this.closeTab">
    <detail
        ref="detail"
        :id="this.tab_msg.id"
        :name="this.tab_msg.name"
        :price="this.tab_msg.price"
        :discount="this.tab_msg.discount"
        :introduce="this.tab_msg.introduce"
        @closeTab='getClose'
    ></detail>
  </template>

  <van-back-top/>
</template>

<script>
import detail from "@/components/CommodityDetail";
import MainMenu from '@/components/MainMenu'
import * as $ from "jquery";
import {showLoadingToast, showToast} from "vant";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    detail,
    MainMenu
  },
  data() {
    return {
      closeTab: false,
      showMenu: false,
      pageLoading: true,
      tab_msg: {
        id: '',
        name: '',
        price: '',
        discount: '',
        imgurl: '',
        introduce: ''
      },
      commdity: [{
        id: '',
        name: '',
        price: '',
        discount: '',
        imgurl: '',
        introduce: ''
      }],
    }

  },
  mounted() {


    this.$requestServers.get("clothes/getAllClothes").then(
        (res) => {
          this.commdity = res;
          this.pageLoading = false
        }
    )
  },
  methods: {
    checkLogin() {
      if (localStorage.getItem("elysova-laundry-id") === '' || localStorage.getItem("elysova-laundry-id") === null) {
        window.open('/#/user/login', '_self')
      }
    },
    idMatch() {
      this.checkLogin()
      //验证id和uuid一致性
      this.$requestServers.post("user/idMatch",
          {
            'id': localStorage.getItem('elysova-laundry-id'),
            'uuid': localStorage.getItem('elysova-chief-id')

          }).then(res => {
        if (!res) {
          window.open('/#/user/login', '_self')
        }
      })
    },

    getClose(value) {
      console.log('执行')
      setTimeout(() => {
        this.closeTab = value;
      }, 300)

    },

    showLab(value) {
      this.closeTab = true;
      this.tab_msg = value
    },

    //
    toOrder() {
      window.open("/#/user/order", '_self')
    }

  }
}
</script>

<style scoped>
/*横屏 css*/
@media screen and (orientation: landscape) {
  .home_body {
    position: relative;
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .commodity_card {
    width: 20vw;
    height: 40vh;

    margin: 1vh;
    overflow: hidden;
    border-radius: 0.5vw;
    transition: all 0.5s;
  }

  .commodity_card:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .commodity_card_img {
    width: 100%;
    height: 100px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .commodity_card_img img{
    /*width: 100%;*/
    height: 80%;
    object-fit: cover;
  }

  .commodity_card_msg {
    width: 90%;
    height: 35%;
    padding: 5%;
  }

  .commodity_card_msg div:nth-child(1) {
    font-size: 20px;
    height: 40%;
  }

  .ccm_price {
    width: 70%;
    height: 35%;
    display: flex;
    align-items: center;
  }

  .ccm_price span:nth-child(1) {

    font-size: 20px;
    margin-right: 5%;
  }

  .ccm_price span:nth-child(2) {
    font-weight: lighter;
    font-size: 18px;
    color: #626262;
    margin-right: 5%;
  }

  .ccm_price span:nth-child(3) {
    color: #f3f3f3;
    padding: 2%;
    border-radius: 5px;
    background-color: green;
  }


}

/*竖屏 css*/
@media screen and (orientation: portrait) {

  .home_body {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .commodity_card {
    width: 45vw;
    margin: 1vh;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    color: #1c1c1c;
    background-color: #ffffff;
  }

  .discount_card {
    box-shadow: 0 0 30px 0 rgba(0, 140, 0, 0.15);
  }

  .commodity_card_img {
    width: 100%;
    height: 100px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .commodity_card_img img{
    /*width: 100%;*/
    height: 60%;
    object-fit: cover;
  }
  .commodity_card_msg {
    width: 90%;
    padding: 10px 15px;
    font-size: 13px;
  }

  .commodity_card_msg div:nth-child(1) {
    height: 40%;
  }

  .ccm_price {
    width: 70%;
    margin: 5px 0;
    display: flex;
    align-items: flex-end;
  }

  .ccm_price span:nth-child(1) {
    font-size: 14px;
    margin-right: 5%;
  }

  .ccm_price span:nth-child(2) {
    font-weight: lighter;
    font-size: 10px;
    color: #626262;
    margin-right: 5%;
  }

  .ccm_price span:nth-child(3) {
    font-size: 10px;
    color: #f3f3f3;
    padding: 5px;
    border-radius: 5px;
    background-color: #008c00;
  }

}

</style>