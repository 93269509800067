<template>
  <button @click="processOrder">
    订单快处
  </button>
</template>

<script>
export default {
  name: "redirectTest",
  methods:{
    processOrder(){
      window.open('/#/security/sentry?orderqp=12321&orderstate=2','_blank')
    }
  }
}
</script>

<style scoped>

</style>