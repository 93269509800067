<template>
    <button
        ref="rButton"
        class="rButton pointer"
        :class="errorMessage?'err err_anim':''"
        :style="
        'color:'+color+';' +
         'border-radius:'+borderRadius+';' +
         'font-weight:'+fontWeight+';'+
         'font-size:'+fontSize+';'+
          'width:'+width+'%;'+'height:'+height+'%;'"
        @click="resetError();$emit('buttonClick')"
    >
      {{errorMessage?errorMessage:value}}
    </button>
</template>

<script>
import * as $ from "jquery";
export default {
  name: "Elysova-Relative-Button",
  props:['value','width','height','backgroundColor','color','borderRadius','fontSize','fontWeight','errorMessage'],
  emits:['buttonClick'],
  mounted() {

  },
  methods:{
    resetError(){
      if(this.errorMessage){
        $(this.$refs.rButton).addClass('err_anim')
      }

      setTimeout(()=>{
        $(this.$refs.rButton).removeClass('err_anim')
      },200)

    }
  }

}
</script>

<style scoped>
  .rButton{
    width: 100%;
    height: 100%;
    border:none;
    background-color: #228bff;
    border-radius: 0.5vw;
    font-size: 60%;
    box-shadow: 0vw 0vw 1vw rgba(0, 0, 0, 0.3);
    transition: all 0.3s;
  }
  .rButton:hover{
    background-color: #1c76d9;
    box-shadow: 0 0 0 #000000;
  }
  .rButton:hover.err{
    background-color: #c40000;
    box-shadow: 0 0 0 #000000;
    color:#dedede;
  }
  .err{
    background-color: #c40000;
    color: #dedede;
  }
  .err_anim{
    animation: rButton_error 0.2s;
  }
  @keyframes rButton_error {
    0%{
      transform: translateX(0);
      background-color: #c40000;
    }
    25%{
      transform: translateX(-2%);
      background-color: #960000;
    }
    50%{
      transform: translateX(0);
      background-color: #be0000;
    }
    75%{
      transform: translateX(2%);
      background-color: #e60000;
    }
    100%{
      transform: translateX(0);
      background-color: #c40000;
    }

  }
</style>