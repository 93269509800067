<template>

  <van-nav-bar title="V 0.9.1.0_alpha Administrator" left-text="返回" left-arrow>
    <template #left>
      <van-icon name="wap-nav" size="2rem" @click="this.showMenu = true"/>
    </template>

  </van-nav-bar>
  <van-notice-bar
      left-icon="volume-o"
      text="今日天气晴朗"
  />

  <van-popup
      v-model:show="this.showMenu"
      position="left"
      :style="{ width: '80%', height: '100%' }"
  >
    <div class="menu_body">
      <div class="menu_list head"><span>&nbsp;</span>Administrator Mobile.</div>

      <a class="menu_list" v-for="items in menuList" :href="items.src">
        <span><van-icon :name="items.icon"/></span>
        <span>&nbsp;{{ items.name }}</span>
      </a>
    </div>
  </van-popup>


</template>

<script>
import {showDialog} from "vant";

export default {
  name: "Admin-Nav",
  data() {
    return {
      showMenu:false,
      menuList: [
        {
          name: '订单过程处理',
          icon: 'completed',
          src: '/#/private/mobile/orderProcess'
        },{
          name: '搜索订单',
          icon: 'completed',
          src: '/#/private/mobile/searchOrder'
        }, {
          name: '账户(停止使用)',
          icon: 'user',
          src: ''
        }, {
          name: '关于(停止使用)',
          icon: '/icons/about.svg',
          src: ''
        }, {
          name: '退出账号',
          icon: '/icons/logout.svg',
          src: '/#/security/login'
        },
      ]
    }
  },
  mounted() {
    this.securityCheck()
  },
  methods:{
    securityCheck() {
      //本地不存在名字和key
      if (localStorage.getItem('elysova_sentry_name') === null || localStorage.getItem('elysova_sentry_token') === null) {
        this.$router.push('/security/login', '_blank')
      } else {
        //检查token
        this.$requestServers.post('manager/admin/checkToken',
            {
              'name': localStorage.getItem('elysova_sentry_name'),
              'token': localStorage.getItem('elysova_sentry_token'),
            }
        ).then(res => {
          if(res.data!==200){
            showDialog({
              title: '提示',
              message: '您的登录信息已过期。',
            }).then(() => {
              this.$router.push('/security/login', '_blank')
            });
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.menu_body {
  width: 100%;
  height: 90%;
}

.menu_body .menu_list.head {
  margin: 50px auto;
  box-shadow: none;
  font-size: 20px;
  font-weight: bolder;
}

.menu_body .menu_list.head span:nth-child(1) {
  background-color: black;
  margin-right: 10px;
  font-weight: bolder;
}

.menu_body .menu_list {
  display: block;
  text-align: center;
  width: 80%;
  font-size: 15px;
  padding: 5%;
  margin: 20px auto;
  border-radius: 15px;
  font-weight: lighter;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
</style>