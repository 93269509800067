<template>
  <div
      :style="'width:'+width+'vw;' +
   'height:'+height+'vh;'"
  >
  <div ref="rInput_border"
       class="rInput_border no-select"
       :class="this.err?'rInput_border_err':''"
       :style="  'border-radius:'+borderRadius+';'"
  >
    <div ref="rInput_text" class="rInput_text" :style="'color:'+placeholderColor+';'">
      {{ placeholder }}
    </div>
    <div class="rInput_input">
      <input ref="rInput_body"
             :type="type"
             :style="'font-size:'+this.fontSize+'px;padding-top:'+this.topDistance+'px;'"
             @click="activeInput(true);$emit('click',true)"
             @focus="activeInput(true)"
             @blur="activeInput(false);$emit('blur',false)"
             :value="modelValue"
             @input="$emit('update:modelValue', $event.target.value)"
      >
      <div class="r_icons">
        <div class="ri_pwd_eye"
             v-if="type=='password'"
             @click="this.$refs.rInput_body.type  = this.$refs.rInput_body.type === 'password'? 'text':'password';this.pwdShow=!this.pwdShow"
        >

          <svg v-if="!this.pwdShow" t="1700113428655" class="icon" viewBox="0 0 1024 1024" version="1.1"
               xmlns="http://www.w3.org/2000/svg" p-id="4049" width="200" height="200">
            <path
                d="M422.4 776.533333l76.8-76.8h8.533333c145.066667 0 251.733333-55.466667 332.8-170.666666-25.6-34.133333-55.466667-64-85.333333-89.6L819.2 384c46.933333 38.4 85.333333 89.6 119.466667 145.066667-98.133333 170.666667-243.2 251.733333-426.666667 251.733333-29.866667 4.266667-59.733333 0-89.6-4.266667z m-238.933333-119.466666c-34.133333-34.133333-68.266667-76.8-98.133334-128 98.133333-170.666667 243.2-251.733333 426.666667-251.733334h46.933333l-85.333333 85.333334c-128 8.533333-226.133333 64-298.666667 166.4 17.066667 25.6 38.4 51.2 59.733334 68.266666l-51.2 59.733334zM755.2 213.333333l59.733333 59.733334L277.333333 810.666667l-59.733333-59.733334L755.2 213.333333z"
                p-id="4050"></path>
          </svg>
          <svg v-if="this.pwdShow" t="1700449090693" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8239" width="200" height="200"><path d="M512 768c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m8.533333-426.666667c-128 0-256 55.466667-328.533333 170.666667 72.533333 115.2 200.533333 170.666667 328.533333 170.666667s238.933333-55.466667 311.466667-170.666667c-72.533333-115.2-183.466667-170.666667-311.466667-170.666667z m-8.533333 298.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z m0-85.333333c25.6 0 42.666667-17.066667 42.666667-42.666667s-17.066667-42.666667-42.666667-42.666667-42.666667 17.066667-42.666667 42.666667 17.066667 42.666667 42.666667 42.666667z" p-id="8240"></path></svg>


        </div>
        <div class="ri_msg"
             v-if="message!=null"
             @mouseenter=" msgControl(true)"
             @mouseleave=" msgControl(false)"
        >
          <svg t="1700100481404" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
               p-id="6173">
            <path
                d="M512 128c212 0 384 172 384 384s-172 384-384 384-384-172-384-384 172-384 384-384m0-64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z"
                p-id="6174"></path>
            <path d="M480 704h64v64h-64zM480 256h64v384h-64z" p-id="6175"></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="rInput_prompt_line" v-if="this.hoverLine && !this.customPercentLine">
      <div class="rInput_hover_line" :style="'background-color:'+this.hoverLineColor+';'"></div>
  </div>
    <div class="rInput_prompt_line" v-if="this.customPercentLine && !this.hoverLine">
      <div class="rInput_custom_line" :style="'background-color:'+this.customPercentLineColor+';width:'+this.linePercent+'%;'"></div>
    </div>
    <div ref="rInput_msg" class="rInput_message"  :style="'font-size:'+this.fontSize+'px;'" v-show="this.show">
      <span>{{ message }}</span>
    </div>
  </div>
  <div class="rInput_error_msg no-select">{{ err }}</div>
  </div>
</template>

<script>
import * as $ from "jquery";

export default {
  name: "Relative-Input",
  props: [
      'width',
      'height',
      'borderRadius',
      'placeholder',
      'type',
      'modelValue',
      'err',
      'message',
      'placeholderColor',
      'customPercentLine',
      'linePercent',
      'customPercentLineColor',
      'hoverLine',
      'hoverLineColor',
  ],
  emits: ['update:modelValue','mouseleave','click','blur'],
  data() {
    return {
      animTopDistance: 0,
      topDistance: 0,
      placeholderSize: 0,
      fontSize: 0,
      action: false,
      pwdShow: false,

      show: false
    }
  },
  mounted() {
    if (this.$refs.rInput_body.value === '' || this.modelValue === '') {
      this.posControl(false);
      this.$refs.rInput_text.style.fontSize = this.placeholderSize + 'px';
      this.$refs.rInput_text.style.top = this.topDistance + 'px';
    } else {
      this.posControl(true);
      //将计算出的值赋给placeholder
      this.$refs.rInput_text.style.fontSize = this.placeholderSize + 'px';
      //将计算出的值赋给输入框的文字大小
      this.$refs.rInput_body.style.fontSize = this.fontSize + 'px';
    }


    //添加事件监听
    //监听窗口缩放
    window.addEventListener("resize", () => {
      this.topDistance = this.$refs.rInput_border.offsetHeight / 2 - this.placeholderSize / 1.5;
      //如果输入框被触发过
      if (this.action) {
        this.posControl(true);
        //将计算出的值赋给placeholder
        this.$refs.rInput_text.style.fontSize = this.placeholderSize + 'px';
        //将计算出的值赋给输入框的文字大小
        this.$refs.rInput_body.style.fontSize = this.fontSize + 'px';
      } else {
        this.posControl(false);
        this.$refs.rInput_text.style.fontSize = this.placeholderSize + 'px';
        this.$refs.rInput_text.style.top = this.topDistance + 'px';
      }
    });


  },
  methods: {
    //位置计算
    posControl(isActive) {
      if (isActive) {
        //单击输入框后对placeholder的控制
        //placeholder点击动画距离顶部的距离
        this.animTopDistance = this.$refs.rInput_border.offsetHeight / 10;
        //将placeholder的字体大小设置为 整体高度/4再/1.2
        //placeholder原始高度为整体高度的四分之一，点击动画会将placeholder设置为原始高度1.2分之一
        //因此placeholder的字体大小 = 整体高度/4/1.2
        this.placeholderSize = this.$refs.rInput_border.offsetHeight / 4 / 1.2;
        //输入框的文字大小为整体高度的五分之一
        this.fontSize = this.$refs.rInput_border.offsetHeight / 5;
      } else {
        //设置placeholder的大小为输入框高度的1/4，并将计算出的值赋值给this.placeholder
        this.placeholderSize = this.$refs.rInput_border.offsetHeight / 4;
        //输入框字体大小 = 整体高度的1/5
        this.fontSize = this.$refs.rInput_border.offsetHeight / 5;
        //placeholder距离顶部的距离设置为垂直居中
        this.topDistance = this.$refs.rInput_border.offsetHeight / 2 - this.placeholderSize / 1.5;
      }
    },

    //input动作
    activeInput(isActive) {
      //flag=true
      //鼠标点击输入框触发的事件
      if (isActive) {
        //当用户输入框内没有数据
        //则加入动画，将placeholder的位置设置移动至输入框上方
        if (this.modelValue === '') {
          this.posControl(true);
          $(this.$refs.rInput_text).stop(true, false).animate({
            top: '2%',
            fontSize: this.placeholderSize + 'px',
          }, 100)
        }
        this.action = isActive;
      }
      //鼠标离开输入框触发的事件
      else {
        //当用户输入框内没有数据
        //则加入动画，将placeholder的位置设置为初始位置
        if (this.modelValue === '') {
          this.posControl(false);
          $(this.$refs.rInput_text).stop(true, false).animate({
            top: this.topDistance + 'px',
            fontSize: this.placeholderSize + 'px',
          }, 100)
          this.action = isActive;
        }
      }
    },

    msgControl(isEnter) {
      if (isEnter) {
        this.show = isEnter
        $(this.$refs.rInput_msg).removeClass('rInput_message_out_anim').addClass("rInput_message_in_anim")
      } else {
        $(this.$refs.rInput_msg).removeClass("rInput_message_anim").addClass('rInput_message_out_anim')
      }
    }

  }
}
</script>
<style scoped>
input[type="password"]::-ms-reveal {
  display: none;
}

input[type="password"]::-ms-clear {
  display: none;
}

input[type="password"]::-o-clear {
  display: none;
}

.rInput_border {
  position: relative;
  border: 0.1vw solid #b7b7b7;
  border-radius: 0.5vw;
  transition: all 0.2s;
  width: 100%;
  height: 100%;
}
.rInput_border:hover{
  border: 0.1vw solid #969696;
}
.rInput_text {
  position: relative;

  height: 0;
  left: 2%;
  transition: letter-spacing 0.5s;
}

.rInput_input {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.rInput_prompt_line{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-bottom-left-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
}
.rInput_prompt_line .rInput_hover_line{
  border-radius: 5%;
  width: 0%;
  height: 100%;
  background-color: #36b2ff;
  transition: width 0.4s;
}
.rInput_input:hover~.rInput_prompt_line .rInput_hover_line{
  width: 100%;
}
.rInput_prompt_line .rInput_custom_line{
  border-radius: 5%;
  width: 0%;
  height: 100%;
  background-color: #36b2ff;
  transition: width 0.4s;
}

.rInput_input input {
  position: relative;
  border: 0;
  width: 75%;
  outline: none;
  background-color: #00000000;
  color: #171717;
  padding-left: 2%;
  -webkit-appearance:none;
}

.r_icons {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.r_icons .ri_pwd_eye, .r_icons .ri_msg {
  width: 45%;
  height: 50%;
  margin: 2%;
  border-radius: 10%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s, backdrop-filter 0.2s;
}

.ri_pwd_eye svg, .ri_msg svg {
  height: 55%;
  fill: #FFFFFF;
}

.r_icons div:hover {
  background-color: rgba(108, 108, 108, 0.5);
  backdrop-filter: blur(20px);
}

.rInput_error_msg {
  position: relative;
  display: block;
  color: #c40000;
  font-size: 2vh;
}

.rInput_message {
  position: relative;
  margin-top: 1%;
  background-color: rgb(190, 190, 190);
  box-shadow: 0.2vw 0.2vw 0.1vw rgba(0, 0, 0, 0.7),
  0.5vw 0.5vw 1vw rgba(0, 0, 0, 0.5);
  width: 90%;
  height: auto;
  padding: 5%;
  border-radius: 0.5vw;
  line-height: 3.5vh;
  z-index: 100;
}

.rInput_message_in_anim {
  animation: rInput_msg 0.5s forwards;
}

.rInput_message_out_anim {
  animation: rInput_msg_out 0.3s forwards;

}

@keyframes rInput_msg {
  0% {
    left: -5%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes rInput_msg_out {
  0% {
    left: 0;
    opacity: 1;
  }
  100% {
    left: -5%;
    opacity: 0;
  }

}


.rInput_border_err{
  animation: rInput_border_err 0.3s forwards;
}

@keyframes rInput_border_err {
  0% {

  }
  100% {
    border-color:#c40000;;
  }

}
</style>