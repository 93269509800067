<template>
  <div class="admin_user_manage">
    <div class="title">用户管理 | 控制台</div>
    <hr>
    <div class="um_nav">
      <div>
        <span style="font-weight: 100;">过滤条件：</span>
        <el-cascader placeholder="请选择过滤条件" v-model="opFilter" :options="options" @change="filter" clearable/>
      </div>

      <div class="um_search">
        <el-input
            v-model="searchInput"
            placeholder="输入信息"
            class="input-with-select"
        >
          <template #prepend>
            <el-button :icon="Search"/>
          </template>
          <template #append>
            <el-select v-model="searchFlag" placeholder="选择" style="width: 115px">
              <el-option label="手机号" value="1"/>
              <el-option label="用户UUID" value="2"/>
              <el-option label="昵称" value="3"/>
            </el-select>
          </template>
        </el-input>
        <div>&nbsp;</div>
        <el-button type="primary" @click="searchUser">搜索</el-button>
      </div>
    </div>
    <div class="nm_list">
      <el-table :data="showList" stripe style="width: 100%" height="500">
        <el-table-column type="expand">
          <template #default="props">
            <div m="4" style="margin-left: 20px">
              <p m="t-0 b-2" v-if="props.row.password">UUID: {{ props.row.uuid }}</p>
              <p m="t-0 b-2" v-if="props.row.password">密码: {{ props.row.password }}</p>
              <p m="t-0 b-2" v-if="props.row.address">地址: {{
                  props.row.address.split("%")[0] + ' ' + props.row.address.split("%")[1] + ' ' + props.row.address.split("%")[2] + ' ' + props.row.address.split("%")[3]
                }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="UID" width="180"/>
        <el-table-column prop="nickname" label="昵称"/>
        <el-table-column prop="phone" label="联系方式"/>
        <el-table-column label="状态">
          <template #default="scope">
            <div v-show="scope.row.state===1">正常</div>
            <div v-show="scope.row.state===0">已封禁</div>
            <div v-show="scope.row.state===-1">已注销</div>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="信用分"/>
        <el-table-column label="操作">
          <template #default="scope">
<!--            <el-button size="small" @click="handleEdit(scope.$index, scope.row)">-->
<!--              Edit-->
<!--            </el-button>-->
            <div v-if="scope.row.state===-1 || scope.row.state===0">
              <el-popconfirm title="您真的要 恢复 这一位用户吗?" @confirm="recover(scope.row.phone)">
              <template #reference>
                <el-button size="small" type="success">恢复</el-button>
              </template>
            </el-popconfirm>

            </div>



            <div v-else>

              <el-popconfirm title="您真的要 注销 这一位用户吗?" @confirm="deleteUser(scope.row.phone)">
              <template #reference>
                <el-button size="small" type="danger">注销</el-button>
              </template>
            </el-popconfirm>

            </div>



          </template>
        </el-table-column>
      </el-table>
    </div>

    <br>
    <div>

      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[5, 10, 15, 20]"
          :small="false"
          :disabled="disabled"
          :background="false"
          layout="sizes, prev, pager, next"
          :total="this.totalNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />

    </div>
  </div>
</template>

<script>
import {Search} from '@element-plus/icons-vue'
import {ElNotification} from "element-plus";

export default {
  name: "admin-user-manager",
  components: {},
  data() {
    return {
      searchInput: '',
      Search: Search,
      searchFlag: '手机号',
      isSearched: false,
      //默认数据量
      totalNumber: 10,
      //过滤器
      opFilter: '',
      //过滤器参数
      options: [
        {
          value: 101,
          label: '用户状态',
          children: [
           {
              value: 1011,
              label: '正常',
            },
            {
              value: 1012,
              label: '已被封禁',
            }, {
              value: 1013,
              label: '已注销',
            },
          ],
        }, {
          value: 102,
          label: '收货地址',
          children: [
            {
              value: '大连交通大学',
              label: '大连交通大学',
              children: [
                {
                  value: '旅顺口校区',
                  label: '旅顺口校区',
                  children: [
                    {
                      value: '一期',
                      label: '一期',
                    },
                    {
                      value: '二期',
                      label: '二期',
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          value:103,
          label: '信用分',
          children: [
            {
              value: 1031,
              label: '正常',
            },
            {
              value: 1032,
              label: '过低',
            }
          ]
        }

      ],
      //查询出的数据
      userList: [],
      //展示列表
      showList: [],
      //搜索列表
      seachedList: [],
      //当前的页数
      currentPage: 1,
      //每一页数据量，默认为10条
      pageSize: 10,


      isFinish:false,
    }
  },
  mounted() {
    this.getUserMsg()
  },
  methods: {

    getUserMsg() {
      /*this.$requestServers.get('manager/admin/user/getAllUserMsg').then(
          res => {
            this.userList = res.data
            //先加入10条数据至showList
            for (let i = 0; i < (this.userList.length < this.pageSize?  this.userList.length : this.pageSize) ; i++) {
              this.showList.push(this.userList[i])
            }
          }
      )*/

      this.$requestServers.get('manager/admin/user/getUserNumber').then(
          res => {
            this.userList.length = res.data
            this.$requestServers.get('manager/admin/user/get',
                {
                  params: {
                    start: this.pageSize * (this.currentPage - 1),
                    number: this.pageSize,
                    resFilter: null,
                    condition: null,
                    searchFilter: null
                  }
                }
            ).then(
                res => {
                  this.showList = res.data
                  if (this.showList.length >= this.pageSize) {
                    this.totalNumber = this.pageSize + 1;
                  }
                }
            )

          }
      )

    },
    getUserListFilter(targetPage, targetSize) {
      if (this.searchFlag) {
        let flag = 'phoneNum';
        switch (this.searchFlag) {
          case '1':
            flag = 'phoneNum';
            break;
          case '2':
            flag = 'uuid';
            break;
          case '3':
            flag = 'nickname';
            break;
          default:
            flag = 'phoneNum';
            break;
        }

        //条件        值
        var filterConval, filterValVal
        console.log(this.opFilter)
        if (this.opFilter != null && this.opFilter.length > 0) {
          filterConval = this.opFilter[0]
          if (this.opFilter[0] === 102) {
            filterValVal = this.opFilter[1] + '%' + this.opFilter[2] + '%' + this.opFilter[3] + '%'
          } else if (this.opFilter[0] === 101) {
            filterValVal = this.opFilter[1]
          }else if(this.opFilter[0] === 103){
            filterValVal = this.opFilter[1]
          }
        } else {
          filterConval = null
          filterValVal = null
        }
       // console.log(filterConval)
        //console.log(filterValVal)


        this.$requestServers.get('manager/admin/user/get',
            {
              params: {
                start: targetSize ? targetSize * (targetPage - 1) : this.pageSize * (this.currentPage - 1),
                number: targetSize ? targetSize : this.pageSize,
                // start: this.pageSize * (this.currentPage - 1),
                // number: this.pageSize,
                filterCon: filterConval,
                filterVal: filterValVal,
                condition: this.searchInput,
                searchFilter: flag
              }
            }
        ).then(
            res => {
              this.showList = res.data
              //console.log(this.showList.length)
              //懒加载未结束
              if(!this.isFinish){
                //当前展示列数小于每页展示数
                if (this.showList.length < this.pageSize) {
                  //重新计算总数
                  this.totalNumber = (this.currentPage - 1) * this.pageSize + this.showList.length
                  this.isFinish = true
                }
                //当前展示列数等于每页展示数量
                else {

                  this.totalNumber = this.pageSize * this.currentPage + 1

                  //this.totalNumber += this.pageSize
                }

              }

            }
        )


      }
    },
    searchUser() {
      this.currentPage = 1
      this.isFinish = false

      this.getUserListFilter()
    },



    handleCurrentChange(value) {
      this.changePage(value, this.pageSize)

    },

    handleSizeChange(value) {
      this.currentPage = 1
      this.isFinish = false
      this.changePage(this.currentPage, value)

    },

    changePage(targetPage, targetSize) {

      this.getUserListFilter(targetPage, targetSize)

      // this.$requestServers.get('manager/admin/user/get',
      //     {
      //       params: {
      //         start: targetSize * (targetPage - 1),
      //         number: targetSize
      //       }
      //     }
      // ).then(
      //     res => {
      //       this.showList = res.data
      //     }
      // )
    },
    recover(phoneNumber){
      this.$requestServers.post("manager/admin/user/reset",
          {
            'phone': phoneNumber,
          }
      ).then(res => {

        if (res.data === 200) {
          this.getUserListFilter(this.currentPage, this.pageSize)
          ElNotification({
            title: 'SUCCESS',
            message: '恢复成功,用户：' + phoneNumber,
            type: 'success',
          })

        } else {

          ElNotification({
            title: 'ERROR',
            message: '位置错误',
            type: 'error',
          })
        }
      })
    },

    deleteUser(phoneNumber) {
      this.$requestServers.post("manager/admin/user/delete",
          {
            'phone': phoneNumber,
          }
      ).then(res => {

        if (res.data === 200) {
          this.getUserListFilter(this.currentPage, this.pageSize)
          ElNotification({
            title: 'SUCCESS',
            message: '注销成功,用户：' + phoneNumber,
            type: 'success',
          })

        } else {

          ElNotification({
            title: 'ERROR',
            message: '位置错误',
            type: 'error',
          })
        }
      })


    }


  }


}


</script>

<style scoped>
.admin_user_manage {
  width: 90%;
  height: 100%;
  padding: 5%;
}

.admin_user_manage .title {
  font-size: 20px;
  font-weight: 900;
}

.admin_user_manage .um_nav {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.um_search {
  display: flex;
}

.nm_list {
  /*height: 65%;*/
  /*overflow-y: scroll;*/
}
</style>