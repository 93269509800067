<template>
  <van-nav-bar
      title="我的订单"
      left-text="返回"
      left-arrow
      fixed
      :placeholder="true"
      @click-left="onClickLeft"
  >
    <template #right>
      <div @click="this.typeSelect = true">
        <van-icon name="bars"/>
        订单状态
      </div>
    </template>
  </van-nav-bar>

  <van-popup
      v-model:show="typeSelect"
      position="right"
      :style="{ width: '40%', height: '100%' }"
  >

    <div class="order_type">
      <div class="ot_title">订单状态</div>
      <div class="ot_card" v-for="items in this.orderType" :class="'state'+items.id +' '+ (items.id===this.changeState? 'focusState' : '')" @click="changeFilter(items.id)">
        {{ items.name }}
      </div>
    </div>
  </van-popup>

  <van-pull-refresh v-model="this.reloadingPage" @refresh="getAllOrder">
    <div class="order_body">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <template v-for="items in this.loadingList">
          <div v-show="this.changeState === -1 || items.state === this.changeState">
            <order-list
                :msg="items"
            />
          </div>
        </template>

      </van-list>
    </div>
  </van-pull-refresh>

  <van-empty
      image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png"
      image-size="80"
      description="暂时没有订单"
      v-show="this.loadingList.length ===0 && this.finished === true"
  />

</template>

<script>

import OrderList from '@/components/OrderList'

export default {
  name: "order",
  data() {
    return {
      img: '',
      orderlist: [],
      loadingList: [],
      loading: false,
      //刷新页面
      reloadingPage:false,

      finished: false,
      loadingIndex: 0,
      typeSelect: false,
      //当前过滤的订单状态
      changeState: 0,

      orderDetail: false,
      orderType: [
        {
          id: -1,
          name: '全部'
        }, {
          id: 0,
          name: '未接单'
        }, {
          id: 1,
          name: '已接单'
        }, {
          id: 2,
          name: '取货中'
        }, {
          id: 3,
          name: '正在进行'
        }, {
          id: 4,
          name: '即将送回'
        }, {
          id: 5,
          name: '正在送回'
        }, {
          id: 6,
          name: '已完成'
        }, {
          id: 10,
          name: '已取消'
        }, {
          id: 11,
          name: '已被退回'
        },

      ]
    }
  },
  components: {
    OrderList,
  },
  mounted() {
    this.idMatch()
    this.getAllOrder()
  },
  methods: {
    checkLogin() {
      if (localStorage.getItem("elysova-laundry-id") === '' || localStorage.getItem("elysova-laundry-id") === null) {
        window.open('/#/user/login', '_self')
      }
    },
    idMatch() {
      this.checkLogin()
      //验证id和uuid一致性
      this.$requestServers.post("user/idMatch",
          {
            'id': localStorage.getItem('elysova-laundry-id'),
            'uuid': localStorage.getItem('elysova-chief-id')

          }).then(res => {
        if (!res) {
          window.open('/#/user/login', '_self')
        }
      })
    },


    getAllOrder() {
      this.$requestServers.post("user/order/getOrder",
          {
            'id': localStorage.getItem('elysova-laundry-id'),
            'uuid': localStorage.getItem('elysova-chief-id'),
          }
      ).then(res => {
        this.pooledData(res)
      })
    },
    /**
     * 订单列表预处理
     * @param list
     */
    pooledData(list) {
      //清空列表
      this.orderlist = []
      for (let i = 0; i < list.length; i++) {
        //是第一个数据
        if (i === 0) {
          //创建一个空结点
          this.orderlist.push(
              {
                orderid: list[i].orderid,
                date: list[i].datetime,
                orderdate:list[i].orderdate,
                address: list[i].address,
                state: list[i].state,
                msg: []
              }
          )
          //将第一个数据加入列表
          this.orderlist[0].msg.push(list[i])

        }
        //不是第一个数据
        else {
          //是否进行插入操作flag
          let isInsert = false;
          //循环次数等于orderlist当前长度
          for (let j = 0; j < this.orderlist.length; j++) {
            //当订单号与当前orderlist第 j 块 orderid相同
            if (list[i].orderid === this.orderlist[j].orderid) {
              //将该条记录添加进第 j 块 数据中
              this.orderlist[j].msg.push(list[i])
              isInsert = true;
              break;
            }
          }
          //如果未进行插入，即没有orderid与其匹配
          //创建一个新的订单区域
          if (!isInsert) {
            //创建一个空结点
            this.orderlist.push(
                {
                  orderid: list[i].orderid,
                  date: list[i].datetime,
                  orderdate:list[i].orderdate,
                  address: list[i].address,
                  state: list[i].state,
                  msg: []
                }
            )
            //将数据加入列表
            this.orderlist[this.orderlist.length - 1].msg.push(list[i])
          }

        }
      }
      //页面刷新/加载完毕
      this.reloadingPage = false;
    },
    changeFilter(id) {
      this.changeState = id
    },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        //一次加载10条数据
        for (let i = this.loadingIndex; this.loadingIndex < i + 10; this.loadingIndex++) {
          // 数据全部加载完成
          if (this.loadingList.length >= this.orderlist.length) {
            this.finished = true;
            break;
          }
          this.loadingList.push(this.orderlist[this.loadingIndex]);
        }

        // 加载状态结束
        this.loading = false;


      }, 1000);
    },


    onClickLeft() {
      history.back()
    },
  }
}
</script>

<style scoped>
.order_body {
  width: 100%;
  height: auto;
}

.order_type {
  overflow: hidden;
  height: 90%;
  padding: 0 15%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.order_type .ot_title {
  position: relative;
  text-align: center;
  font-size: 15px;
  font-weight: bolder;
  margin: 10px 0;
}

.ot_card {
  font-weight: 100;
  width: 70%;
  padding: 10%;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 12px;
  margin: 10px 0;
}

.focusState{
  animation:focus_animation 0.5s forwards;
}
@keyframes focus_animation {
  0%{
    width: 70%;
    font-weight: 100;
  }
  100%{
    width: 90%;
    font-weight: 900;
  }
}
/*未接单*/
.state0 {
  box-shadow: 0 0 10px 0 #c40000;


}

/*已接单*/
.state1 {
  box-shadow: 0 0 10px 0 #008cff;

}

/*取货中*/
.state2 {
  box-shadow: 0 0 10px 0 #8207b6;

}

/*正在进行*/
.state3 {
  box-shadow: 0 0 10px 0 #20ad00;

}

/*即将返回*/
.state4 {
  box-shadow: 0 0 10px 0 #b69a09;

}

/*正在送回*/
.state5 {
  box-shadow: 0 0 10px 0 #ff690b;

}

/*已完成*/
.state6 {
  box-shadow: 0 0 10px 0 #ababab;

}

/*已取消*/
.state10 {
  box-shadow: 0 0 10px 0 #525252;

}

/*已被退回*/
.state11 {
  box-shadow: 0 0 10px 0 #560000;

}
</style>