<template>
  <div class="address_manager">
    <van-loading v-show="!this.university">加载中</van-loading>
    <div class="left" v-show="this.university">
      <div class="title">地址：</div>
      <div class="detail">
        {{ this.university }}
        {{ this.campus }}
        {{ this.detail }}
        <br>
        <span><b>{{this.detail2}}</b></span>
      </div>
    </div>
    <div class="right" @click="this.showPad = true">
      <van-icon name="edit" size="20px"/>
    </div>
    <van-popup
        v-model:show="this.showPad"
        position="bottom"
        :style="{ height: '55%' }"
    >
      <br>
      <div style="width: 80%;height: 75%;margin: 0 auto;">
        <van-area
            title="选择新地址"
            :area-list="areaList"
            @confirm="confirmAdd"
            @cancel="this.showPad = false"
        />
      </div>

      <div style="width: 80%;height: 10%;margin: 0 auto;">
        <elysova-input
            width="80"
            height="8"
            border-radius="10px"
            placeholder="详细地址(寝室楼号、宿舍号等)"
            type="text"
            :err="inputErrMsg"
            font-size="20"
            style="position:relative;"
            v-model="e_detail2"
            @blur="checkDetail2(false)"
            @click="checkDetail2(true)"
        />
      </div>

    </van-popup>

  </div>
</template>

<script>
import elysovaInput from '@/components/Elysova-Relative-Input'
import {showFailToast, showSuccessToast, showToast} from "vant";
export default {
  name: "Address-Manager",
  components:{
    elysovaInput
  },
  data() {
    return {
      add: '',
      showPad:false,
      university: '',
      campus: '',
      detail: '',
      detail2:'',

      e_detail2:'',
      areaList : {
        province_list: {
          110000: '大连交通大学',
        },
        city_list: {
          110100: '旅顺口校区',
        },
        county_list: {
          110101: '一期',
          110102: '二期',
        },
      },


      inputErrMsg:'',
    }
  },
  mounted() {
    this.getAddress();
  },
  methods: {
    getAddress() {
      this.$requestServers.post("user/getAddress",
          {
            'id': localStorage.getItem('elysova-laundry-id'),
            'uuid': localStorage.getItem('elysova-chief-id')
          }
      ).then(res => {

          this.university = res.data.split("%")[0]
          this.campus = res.data.split("%")[1]
          this.detail = res.data.split("%")[2]
          this.detail2 = res.data.split("%")[3]


      })

    },
    checkDetail2(clickFlag){
      if(clickFlag){
        this.inputErrMsg = ''
      }else if (this.e_detail2===''){
        this.inputErrMsg = "必填"
      }else{
        this.inputErrMsg = ''
      }
    },
    confirmAdd(values){

      if(this.e_detail2===''){
        showToast('请输入详细地址');
      }else{
        let newAdd = values.selectedOptions[0].text + '%' + values.selectedOptions[1].text+ '%' + values.selectedOptions[2].text+ '%' +this.e_detail2
        this.$requestServers.post("user/updateAddress",
            {
              'id': localStorage.getItem('elysova-laundry-id'),
              'uuid': localStorage.getItem('elysova-chief-id'),
              'address':newAdd
            }
        ).then(res => {
          if(res.data===200){
            showSuccessToast('修改成功');
            this.university = values.selectedOptions[0].text
            this.campus = values.selectedOptions[1].text
            this.detail = values.selectedOptions[2].text
            this.detail2 = this.e_detail2

            this.showPad = false
          }else{
            showFailToast(res.data.msg);
            this.showPad = false
          }
        })
      }

    }
  }
}
</script>

<style scoped>
.address_manager {
  width: 90%;
  background-color: #ffffff;
  border-radius: 20px;
  margin: 20px 0;
  padding: 5%;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.detail {
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.8);
}


</style>