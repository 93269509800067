<template>

  <items-pad
      :title="'确认您的预约信息'"
      @closeTab="this.$emit('closeTab', false)"
  >
    <address-manager/>
    <commodity-list
        v-for="items in cartList"
        :name="items.clothes.name"
        :price="((items.clothes.price * (items.clothes.discount === -1 ? 1 : (items.clothes.discount / 10))) * items.number).toFixed(2)"
        :date="items.date"
        :number="items.number"
        v-show="!this.isdirect"
    />
    <commodity-list
        :name="name"
        :price="((price * (discount === -1 ? 1 : (discount / 10))) * number).toFixed(2)"
        :date="date"
        :number="number"
        v-show="this.isdirect"
    />

    <div class="vas_body">
      <div>选取增值服务</div>
      <div class="vas_scroll">
        <div class="vas_list" v-for="items in this.vas">
          <div>{{items.name}}</div>
          <div class="price">￥{{items.price.toFixed(2)}}</div>
          <van-stepper v-model="this.test" min="1" max="10" disable-input integer/>

        </div>
      </div>



    </div>


    <div class="totalPrice">
      <span>取件后支付：</span>
      <span>￥</span>
      <span>{{ isdirect ? ((price * (discount === -1 ? 1 : (discount / 10))) * number).toFixed(2) : totalPrice }}</span>
    </div>
    <div class="check_order_btn">
      <input type="button" value="确认" @click="confirmBtn">
    </div>
  </items-pad>
</template>

<script>
import * as $ from "jquery";
import itemsPad from '@/components/Elysova-Items-Pad'
import commodityList from '@/components/Commodity-List'
import addressManager from '@/components/Address-Manager'
import {showFailToast, showLoadingToast, showSuccessToast} from "vant";

export default {
  name: "checkorder",
  props: ['cartList', 'totalPrice', 'oid', 'isdirect', 'name', 'price', 'discount', 'date', 'number', 'cid'],

  components: {
    commodityList,
    addressManager,
    itemsPad
  },
  data(){
    return{
      vas:[],

    }
  },
  mounted() {
    this.getVas();
  },
  methods: {
    closeTab() {
      $(this.$refs.checkorder).addClass("fade-out")
      this.$emit('closeTab', false)
    },
    idMatch() {
      if (localStorage.getItem("elysova-laundry-id") === '' || localStorage.getItem("elysova-laundry-id") === null) {
        window.open('/#/user/login', '_self')
      }
      //验证id和uuid一致性
      this.$requestServers.post("user/idMatch",
          {
            'id': localStorage.getItem('elysova-laundry-id'),
            'uuid': localStorage.getItem('elysova-chief-id')
          }).then(res => {
        if (!res) {
          window.open('/#/user/login', '_self')
        }
      })
    },
    getVas(){
      this.$requestServers.get("clothes/vas/getVas").then(res => {
        this.vas = res
      })
    },
    confirmBtn() {
      this.idMatch()

      showLoadingToast({
        message: '加载中...',
        forbidClick: true,
      });

      setTimeout(() => {
        if (this.isdirect) {
          this.$requestServers.post("clothes/placeOrderDirect",
              {
                'datetime': new Date(this.date),
                'uid': localStorage.getItem('elysova-laundry-id'),
                'cid': this.cid,
                'number': this.number,
                'price': this.price
              }
          ).then(res => {
            showSuccessToast('成功下单');
            setTimeout(() => {

              location.reload()

            }, 500)
          })
        } else {
          this.$requestServers.post("clothes/placeOrderOnCart",
              this.cartList
          ).then(res => {
            showSuccessToast('成功下单');
            setTimeout(() => {
              location.reload()
            }, 500)
          })
        }
      }, 500)

    }


  }
}
</script>

<style scoped>
.vas_body{
  width: 90%;
  padding: 5%;
  border-radius: 15px;
  background-color: #ffffff;
  color: #626262;
}
.vas_scroll{
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
}
.vas_list{
  display: inline-block;
  width: 40%;
  padding: 5%;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  margin: 20px 10px;
  font-size: 14px;
}
.vas_list .price{
  font-size: 14px;
  margin: 10px 0;
  color: #ff5e17;
}
.totalPrice {
  font-weight: bolder;
  margin: 20px 0;
}

.totalPrice span:nth-child(1) {
  font-size: 14px;
}

.totalPrice span:nth-child(2) {
  font-size: 14px;
  color: #ff5e17;
}

.totalPrice span:nth-child(3) {
  font-size: 30px;
  color: #ff5e17;
}

.check_order_btn {
  width: 100%;
  height: 60px;
}

.check_order_btn input {
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  color: #fafafa;
  font-size: 14px;
  background-color: #0078f1;
  border: 2px solid #0078f1;
  font-weight: bolder;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
}
</style>