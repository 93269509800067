<template>

  <items-pad
      :title="this.name"
      :discount="this.discount"
      @closeTab="this.$emit('closeTab', false)"
  >

    <div class="detail_msg">
      <div class="dm_price">
          <span>
            ￥{{
              ((this.price * (this.discount === -1 ? 1 : (this.discount / 10))) * this.number).toFixed(2)
            }}
          </span>
        <span v-if="this.discount !== -1"><del>￥{{ this.price * number }}</del> </span>
      </div>
      <hr>
      <br>
      <div class="dm_intro">
        {{ this.introduce }}
      </div>

      <br>
      <hr>

      <div class="dm_datenumber">
        <div>
          <span class="f-15s">取件日期：</span>
          <span class="dmd_date" @click="this.showPickCalender=true">
              {{
              this.nowDate.split("-")[0] + ' 年 ' + this.nowDate.split("-")[1] + ' 月 ' + this.nowDate.split("-")[2] + ' 日'
            }}
            <van-icon name="edit" size="20px"/>
            </span>
        </div>

        <br>

        <div class="f-15">件数：
          <span class="dm_number">
              <van-stepper v-model="this.number" min="1" max="99" input-width="20%" button-size="32px" disable-input integer/>
            </span>
        </div>


      </div>
      <br>


      <div class="dm_button">
        <input class="pointer" type="button" value="加入购物车" @click="addToCart">
        <input class="pointer" type="button" value="预约" @click="pay">
      </div>
    </div>

  </items-pad>


  <van-calendar
      v-model:show="this.showPickCalender"
      :default-date="new Date(this.nowDate)"
      @confirm="confirmDate"
  />
  <template v-if="this.showCheckOrder">
    <checkorder
        ref="checkorder"
        :name="name"
        :price="price"
        :discount="discount"
        :number="number"
        :date="pickedDate"
        :cid="id"
        :isdirect="true"
        @closeTab='getCloseConfirm'
    >
    </checkorder>
  </template>


</template>

<script>


import {ElMessage} from "element-plus";
import {showFailToast, showLoadingToast, showSuccessToast} from "vant";
import * as $ from "jquery";
import checkorder from "@/components/checkorder";
import itemsPad from '@/components/Elysova-Items-Pad'

const now = new Date();
export default {
  name: "detail",
  props: ['name', 'price', 'discount', 'imgurl', 'introduce', 'id', 'address'],
  components: {
    checkorder,
    itemsPad,
  },
  data() {
    return {
      number: 1,
      showCheckOrder: false,
      showPickCalender: false,

      pickedDate: '',

      nowDate: ''

    }
  },
  mounted() {
    this.nowDate = this.getSimpleDate(new Date())
    this.pickedDate = this.nowDate
  },
  methods: {
    checkLogin() {
      if (localStorage.getItem("elysova-laundry-id") === '' || localStorage.getItem("elysova-laundry-id") === null) {
        window.open('/#/user/login', '_self')
      }
    },
    idMatch() {
      this.checkLogin()
      //验证id和uuid一致性
      this.$requestServers.post("user/idMatch",
          {
            'id': localStorage.getItem('elysova-laundry-id'),
            'uuid': localStorage.getItem('elysova-chief-id')
          }).then(res => {
        if (!res) {
          window.open('/#/user/login', '_self')
        }
      })
    },

    getDaysBetweenDates(date1, date2) {
      // 将字符串转换为Date对象
      var day1 = new Date(date1);
      var day2 = new Date(date2);
      // 获取时间戳（单位：毫秒）
      var timeDiff = day2.getTime() - day1.getTime();
      // 计算天数并返回结果
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    },

    getSimpleDate(e) {
      const year = e.getFullYear();
      const month = e.getMonth() + 1;
      const day = e.getDate();
      return year + '-' + month + '-' + day
    },
    addToCart() {
      this.idMatch();
      if (this.pickedDate === '') {
        showFailToast('取件日期不能为空');
      } else if (this.getDaysBetweenDates(now.toLocaleDateString(), this.pickedDate) < 0) {
        showFailToast('取件日期已过时');
      } else {
        showLoadingToast({
          message: '加载中...',
          forbidClick: true,
        });
        this.$requestServers.post("clothes/addToCart",
            {
              'id': '',
              'uid': localStorage.getItem('elysova-laundry-id'),
              'cid': this.id,
              'number': this.number,
              'date': new Date(this.pickedDate),
            }
        ).then(() => {
          showSuccessToast('添加成功');
          setTimeout(()=>{
            this.$emit('closeTab', false)
          },500)

        })
      }


    },
    getCloseConfirm(value) {
      setTimeout(() => {
        this.showCheckOrder = value
      }, 300)

    },
    pay() {
      this.checkLogin()
      if (this.pickedDate === null || this.pickedDate === '') {
        showFailToast('取件日期不能为空');
      } else {
        this.showCheckOrder = true
      }

    },
    confirmDate(value) {
      this.pickedDate = this.getSimpleDate(value)
      this.nowDate = this.pickedDate
      this.showPickCalender = false
    }
  }
}
</script>

<style scoped>
/*横屏 css*/
@media screen and (orientation: landscape) {
  .detail {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .detail_body {
    width: 60%;
    height: 80%;
    background-color: white;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  }

  .detail_head {
    width: 98%;
    height: 10%;
    padding: 0 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .detail_head div:nth-child(1) {
    font-size: 10px;
    color: #dedede;
  }

  .detail_head div:nth-child(2) {
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    transition: background-color 0.5s;
  }

  .detail_head div:nth-child(2):hover {
    background-color: #f1f1f1;
  }

  .detail_msg {
    width: 90%;
    height: 75%;
    padding: 5%;
  }

  .detail_msg .dm_title {
    font-size: 50px;

  }

  .detail_msg .dm_title span {
    background-color: #ffb85a;
  }

  .detail_msg .dm_title .discount_msg {
    color: #f3f3f3;
    padding: 1%;
    border-radius: 5px;
    background-color: green;
    font-size: 15px;
  }

  .detail_msg div:nth-child(2) {
    font-size: 30px;
  }

  .dm_price {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: flex-end;
  }

  .dm_price span:nth-child(1) {
    font-weight: bolder;
    font-size: 40px;
    margin-right: 1%;
  }

  .dm_price span:nth-child(2) {
    font-weight: lighter;
    font-size: 25px;
    color: #626262;
    margin-right: 1%;
  }

  .dm_intro {
    width: 100%;
    height: 30%;
    font-size: 20px;
    font-weight: lighter;
  }


  .dm_number {
    margin-left: 20px;
  }

  .dm_number input {
    -webkit-appearance: none; /* Safari and Chrome */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Standard syntax */
    outline: none;
  }

  .dm_number input:nth-child(1) {
    color: #fafafa;
    width: 25px;
    height: 25px;
    background-color: #545454;
    font-weight: lighter;
    margin-right: 5px;
    border: none;
  }

  .dm_number input:nth-child(2) {
    width: 50px;
    height: 25px;
    border: none;
    text-align: center;
    font-weight: bolder;
  }

  .dm_number input:nth-child(3) {
    color: #fafafa;
    width: 25px;
    height: 25px;
    background-color: #545454;
    font-weight: lighter;
    border: none;
  }

  .dm_button {
    width: 100%;
    height: 25%;
  }

  .dm_button input {
    border: none;
    height: 50%;

    transition: all 0.5s;
    outline: none;
  }


  .dm_button input:nth-child(1) {
    width: 30%;
    color: #fafafa;
    font-size: 18px;
    background-color: #545454;
    border: 2px solid #545454;
    font-weight: lighter;
    margin-right: 2%;
    border-radius: 15px;
  }

  .dm_button input:nth-child(2) {
    width: 60%;
    color: #fafafa;
    font-size: 18px;
    background-color: #0078f1;
    border: 2px solid #0078f1;
    font-weight: bolder;
    border-radius: 15px;
  }

  .dm_button input:nth-child(1):hover {
    color: #545454;
    background-color: rgba(0, 0, 0, 0);
  }

  .dm_button input:nth-child(2):hover {
    color: #0078f1;
    background-color: rgba(0, 0, 0, 0);
  }
}


/*竖屏 css*/
@media screen and (orientation: portrait) {

  .detail_msg {
    width: 100%;
  }

  .dm_price {
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  .dm_price span:nth-child(1) {
    font-weight: bolder;
    font-size: 35px;
    margin-right: 10px;
  }

  .dm_price span:nth-child(2) {
    font-weight: lighter;
    font-size: 18px;
    color: #c7c7c7;
    margin-right: 1%;
  }

  .dm_intro {
    width: 100%;
    font-size: 15px;
    font-weight: lighter;
    color: rgba(0, 0, 0, 0.8);
  }

  .dm_datenumber {
    font-weight: lighter;
    margin: 20px 0;
  }

  .dmd_date {
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    font-weight: 900;
    color: #737373;
  }


  .dm_button {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .dm_button input {
    margin: 10px 0;
    border: none;
    height: 60px;
    width: 100%;
    transition: all 0.5s;
    outline: none;
    border-radius: 15px;
    font-size: 14px;
    -webkit-appearance: none;
  }


  .dm_button input:nth-child(1) {
    color: #fafafa;
    background-color: #545454;
    border: 2px solid #545454;
    font-weight: lighter;
  }

  .dm_button input:nth-child(2) {
    color: #fafafa;
    background-color: #0078f1;
    border: 2px solid #0078f1;
    font-weight: bolder;
  }

  .dm_button input:nth-child(1):hover {
    color: #545454;
    background-color: rgba(0, 0, 0, 0);
  }

  .dm_button input:nth-child(2):hover {
    color: #0078f1;
    background-color: rgba(0, 0, 0, 0);
  }
}

</style>