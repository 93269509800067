<template>
  <div class="login_body">
    <div class="l_title">登录</div>
    <br>
    <elysova-input
        width="90"
        height="8"
        border-radius="10px"
        placeholder="手机号"
        type="text"
        :err="inputErrMsg"
        font-size="20"
        style="position:relative;"
        v-model="phoneNumber"
        @blur="checkPhoneNumber(false)"
        @click="checkPhoneNumber(true)"
    ></elysova-input>
    <br>
    <br>
    <elysova-button
        width="100"
        height="15"
        border-radius="10px"
        value="登录"
        font-size="20px"
        font-weight="100"
        color="#fafafa"
        :error-message="btnErrMsg"
        @button-click="userLogin"
    ></elysova-button>
  </div>
</template>

<script>
import ElysovaInput from '@/components/Elysova-Relative-Input'
import ElysovaButton from '@/components/Elysova-Relative-Button'
const telReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
export default {
  name: "login",
  components:{
    ElysovaInput,
    ElysovaButton
  },
  data(){
    return{
      phoneNumber:'',
      inputErrMsg:'',
      btnErrMsg:'',
    }
  },
  mounted() {
    localStorage.setItem('elysova-laundry-id','')
    localStorage.setItem('elysova-chief-id','')
  },
  methods:{
    checkPhoneNumber(clickFlag){
      if(clickFlag){
        this.inputErrMsg = ''
        this.btnErrMsg = ''
      }else if (this.phoneNumber===''){
        this.showErrMsg('必填','')
      }else{
        this.inputErrMsg = ''
      }
    },
    userLogin(){
      if(this.phoneNumber === ''){
        this.showErrMsg('重新输入','手机号不能为空')
      }
      else if(telReg.test(this.phoneNumber)){
        this.$requestServers.get("user/userLogin", {params:{phoneNumber:this.phoneNumber}}).then(res=>{
          if(!res){
            this.showErrMsg('重新输入','用户不存在')
          }else{
            localStorage.setItem('elysova-laundry-id',res.id)
            localStorage.setItem('elysova-chief-id',res.uuid)
            window.open('/','_self')
          }
        })
      }else{
        this.showErrMsg('重新输入','手机号格式错误')
      }
    },
    showErrMsg(iMsg,bMsg){
      this.inputErrMsg = iMsg
      this.btnErrMsg = bMsg
    }
  }

}
</script>

<style scoped>
.login_body{
  width: 90%;
  height: 55%;
  padding: 45% 5%;
}
.login_body .l_title{
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
}
</style>