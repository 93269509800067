<template>
<!--  移动端-->
  <template v-if="this.isMobile">
    <admin-mobile/>
  </template>

<!--  PC端-->
  <template v-if="!this.isMobile">
    <admin-pc/>
  </template>
</template>

<script>
import adminMobile from '@/views/admin/mobile/admin-mobile'
import adminPc from '@/views/admin/pc/admin-pc'

export default {
  name: "view-control",
  components:{
    adminMobile,
    adminPc,
  },
  data(){
    return{
      isMobile:true,
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
    }
  },
  mounted() {
    this.isMobile = this.screenWidth / this.screenHeight < 1;
    this.listenSize()
  },
  methods:{
    listenSize(){
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth
          this.screenHeight = document.body.clientHeight
          this.isMobile = this.screenWidth / this.screenHeight < 1;
        })()
      }
    }
  }
}
</script>

<style scoped>

</style>