<template>
  <div class="menu_body">
    <div class="menu_list head"><span>&nbsp;</span>Elysova Technology.</div>

    <a class="menu_list" v-for="items in menuList" :href="items.src">
      <span><van-icon :name="items.icon"/></span>
      <span>&nbsp;{{ items.name }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  data() {
    return {
      menuList: [
        {
          name: '购物车',
          icon: 'shopping-cart',
          src: '/#/store/carts'
        }, {
          name: '我的订单',
          icon: 'completed',
          src: '/#/user/order'
        }, {
          name: '账户(停止使用)',
          icon: 'user',
          src: ''
        }, {
          name: '反馈(停止使用)',
          icon: 'service',
          src: ''
        }, {
          name: '关于(停止使用)',
          icon: '/icons/about.svg',
          src: ''
        }, {
          name: '退出账号',
          icon: '/icons/logout.svg',
          src: '/#/user/login'
        },
      ]
    }
  },


}
</script>

<style scoped>
.menu_body {
  width: 100%;
  height: 90%;
}

.menu_body .menu_list.head {
  margin: 50px auto;
  box-shadow: none;
  font-size: 20px;
  font-weight: bolder;
}

.menu_body .menu_list.head span:nth-child(1) {
  background-color: black;
  margin-right: 10px;
  font-weight: bolder;
}

.menu_body .menu_list {
  display: block;
  text-align: center;
  width: 80%;
  font-size: 15px;
  padding: 5%;
  margin: 20px auto;
  border-radius: 15px;
  font-weight: lighter;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}


</style>