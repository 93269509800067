<template>
  <items-pad
    :title="'预约详情'">
    <div class="order_detail_body">

      <div ref="order_QRcode" class="order_QRcode" @click="getQR">
        <div class="oq_alert_msg no-select" v-show="!this.showQR">
          <van-icon name="arrow-down" />&nbsp;展示预约码
        </div>
        <div ref="QRCodeimg" class="oq_container fade-in" v-show="this.showQR">
          <van-loading v-show="this.QRLoading"/>
          <img :src="this.QRCode" alt="">
          <div>{{this.msg.orderid}}</div>
          <div>下单时间：{{this.msg.orderdate}}</div>
        </div>
      </div>

      <div class="orderList" >
        <commodity-list
            v-for="items in msg.msg"
          :name="items.clothes.name"
          :price="items.price"
          :number="items.number"
        />
      </div>

      <div class="operation_input">
        <input class="cancel" v-if="this.msg.state === 0" type="button" value="取消" @click="cancelOrder">
        <input class="comfirm" v-if="this.msg.state === 5" type="button" value="确认收货" @click="checkDelivery">
      </div>

    </div>
  </items-pad>
</template>

<script>
import * as $ from "jquery";
import itemsPad from "@/components/Elysova-Items-Pad"
import commodityList from '@/components/Commodity-List'
import {showConfirmDialog, showFailToast, showSuccessToast} from "vant";
export default {
  name: "OrderDetail",
  props:['msg'],
  components:{
    itemsPad,
    commodityList,
  },
  data(){
    return{
      QRCode:'',

      showQR:false,
      QRRequested:false,
      QRLoading:true,
      originHeight:'0',
      targetHeight:'0',
    }
  },
  mounted() {
    console.log(this.msg)
    this.originHeight = this.$refs.order_QRcode.offsetHeight
    this.targetHeight = this.$refs.order_QRcode.offsetWidth
  },
  methods:{
    /**
     * 获取二维码
     */
    getQR(){
      if(!this.QRCode && !this.QRRequested){
        this.$requestServers.get("qrcode/getQRCodeBase64",
            {
              params:{
                // https://www.anyatech.top/#/security/sentry?orderqp=12321&orderstate=2
                content:'https://www.anyatech.top/#/security/sentry?orderqp=' + this.msg.orderid + '&orderstate=' + this.msg.state
              }
            }
        ).then(res => {
            this.QRLoading = false
            this.QRCode = res.data
            let qrImg = new Image();
            qrImg.src = this.QRCode

        })
      }
      if(!this.showQR){
        $(this.$refs.order_QRcode).stop(true, false).animate({
          height:this.targetHeight +50 + 'px',
        }, 100)
      }else{
        $(this.$refs.order_QRcode).stop(true, false).animate({
          height:this.originHeight+ 'px',
        }, 100)
      }

      this.showQR = !this.showQR
    },


    /**
     * 取消订单
     */
    cancelOrder() {
      showConfirmDialog({
        title: '您正在取消订单',
        message:
            '确定取消吗？',
      })
          .then(() => {
            //取消订单

            this.$requestServers.post("user/order/cancelOrder",
                {
                  'orderid':this.msg.orderid,
                  'id':localStorage.getItem('elysova-laundry-id'),
                  'uuid':localStorage.getItem('elysova-chief-id'),
                }
            ).then(res => {

              if(res>0){
                showSuccessToast('取消成功');
                location.reload()
              }else{
                showFailToast('未知错误:(');
              }

            })

          })
          .catch(() => {
            // on cancel
          });
    },

    checkDelivery() {
      showConfirmDialog({
        title: '您正在确认收货',
        message:
            '确定收货吗？',
      })
          .then(() => {
            //取消订单

            this.$requestServers.post("user/order/checkDelivery",
                {
                  'orderid':this.msg.orderid,
                  'id':localStorage.getItem('elysova-laundry-id'),
                  'uuid':localStorage.getItem('elysova-chief-id'),
                }
            ).then(res => {

              if(res>0){
                showSuccessToast('收货成功，订单关闭');
                location.reload()
              }else{
                showFailToast('未知错误:(');
              }

            })

          })
          .catch(() => {
            // on cancel
          });
    },
  }
}
</script>

<style scoped>
.order_detail_body{
  width: 100%;
}
.order_QRcode {
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  border-radius: 15px;
  background-color: #FFFFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.order_QRcode .oq_container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order_QRcode .oq_alert_msg{
  margin: 20px 0;
  font-size: 15px;
  font-weight: 100;
  color: #626262;
}
.order_QRcode .oq_container img{
  width: 100%;
}
.order_QRcode .oq_container div{
  text-align: center;
  font-size: 10px;
  font-weight: 100;
  margin-bottom: 10px;
  color: #626262;
}
.orderList{
  overflow: hidden;
  width: 90%;
  padding:1% 5%;
  background-color: #ffffff;
  border-radius: 25px;
  margin: 20px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.operation_input{
  width: 100%;
  height: 60px;
}
.operation_input input{
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 15px;
  -webkit-appearance:none;
}
.operation_input .cancel{
  border: 2px solid #c40000;
  background-color: #c40000;
  color: white;
}

.operation_input .comfirm{
  border: 2px solid #0078f1;
  background-color: #0078f1;
  color: white;
}
</style>