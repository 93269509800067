<template>
  <admin-nav></admin-nav>
  <div class="order_qp_body">
    <div class="oqp_title">订单快处</div>
    <div class="oqp_logo">
      <van-icon name="warning-o" v-show="!success" color="#c40000" size="100px"/>
      <van-icon name="passed" v-show="success" color="#20ad00" size="100px"/>
    </div>
    <div class="oqp_id" v-show="!success">{{this.orderid}}</div>
    <div class="oqp_id" v-show="success">成功处理</div>
    <div class="oqp_btn" v-if="!success">
      <input type="button" value="确认取货" @click="comfirmCollect">
    </div>
  </div>
</template>

<script>
import adminNav from '@/components/admin/Admin-Nav'
import {showFailToast, showToast} from "vant";
export default {
  name: "orderQP",
  components:{
    adminNav
  },
  data(){
    return{
      orderid:this.$route.query.id,
      success:false,
    }
  },
  mounted() {

  },
  methods:{
    comfirmCollect(){
      this.$requestServers.post("manager/admin/order/updateState",
          {
            'uuid': this.orderid,
            'state': 3,
          }
      ).then(res => {
        if (res.data === 200) {
          this.success = true
        } else {
          showFailToast('未知错误，请重试');
        }
      })
    }
  }
}
</script>

<style scoped>
.order_qp_body{
  width: 90%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order_qp_body .oqp_title{
  font-size: 30px;
  font-weight: 100;
  margin: 50px 0;
}
.order_qp_body .oqp_id{
  font-size: 15px;
  font-weight: 100;
  margin-top: 40px;
}
.order_qp_body .oqp_btn{
  width: 80%;
  height: 50px;
}
.order_qp_body .oqp_btn input{
  width: 100%;
  height: 100%;
  margin-top: 50px;
  border-radius: 12px;
  box-sizing: border-box;
  color: #dedede;
  font-weight: 100;
  background-color: #0078f1;
  border: 1px solid #0078f1;
}

</style>