import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import request from "@/utils/request";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import Vant from 'vant';
import 'vant/lib/index.css';

const app = createApp(App);

app.config.globalProperties.$globalVar = 'https://anyatech.top:9090';
app.config.globalProperties.$requestServers = request;


app.use(ElementPlus, {
    locale: zhCn,
})
app.use(router)
app.use(Vant);
app.mount('#app')