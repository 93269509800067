<template>
  <admin-nav/>
  <div>
    <van-dropdown-menu>
      <van-dropdown-item v-model="searchType" :options="searchTypeOption"/>
    </van-dropdown-menu>
    <div class="search_order_input">
      <input type="text" v-model="searchValue" :placeholder="'请输入'+this.searchTypeOption[this.searchType].text">
    </div>
    <div class="search_order_submit">
      <input type="button" value="查询" @click="searchOrder">
    </div>
    <hr>
    <div class="so_loading" v-show="isSearch">
      <van-loading />
    </div>

    <div class="search_order_result">
      <div class="sor_title">搜索到<b>{{ orderlist.length }}</b>条结果</div>
      <div class="sor_order_list" v-for="items in this.orderlist">
        <div class="sol_id">ID:{{ items.orderid }}</div>
        <br>
        <div class="sol_date">下单日期：{{ items.orderdate }}</div>
        <div class="sol_date">取件日期：{{ items.date }}</div>
        <div class="">订单状态：{{ items.state }}</div>
        <div class=""></div>
        <div class="sol_items" v-for="item in items.msg">
          <div><span>项目：{{ item.clothes.name }}</span></div>
          <div><span>数量：{{ item.number }}</span></div>
          <div><span>价格：￥{{ item.price.toFixed(2) }}</span></div>
        </div>

        <div>
          <van-popover :actions="actions" @select="changeState">
            <template #reference>
              <van-button type="default" @click="this.editId = items.orderid;">修改状态</van-button>
            </template>
          </van-popover>

        </div>


      </div>
    </div>
  </div>
</template>

<script>
import adminNav from "@/components/admin/Admin-Nav";
import {showConfirmDialog, showToast} from "vant";

export default {
  name: "search-order-m",
  components: {
    adminNav,
  },
  data() {
    return {
      searchValue: '',
      searchType: 0,
      searchTypeOption: [
        {text: '联系方式', value: 0},
        {text: '订单号', value: 1},
      ],
      isSearch:false,

      actions: [
        {text: '接单'},
        {text: '取货'},
        {text: '正在进行'},
        {text: '即将送回'},
        {text: '正在送回'},
        {text: '退回'},
      ],
      //订单显示列表
      orderlist: [],
      editId: '',

    }
  },
  methods: {
    searchOrder() {
      this.isSearch = true
      this.$requestServers.get('/manager/admin/mobile/searchOrder',
          {
            params: {
              value: this.searchValue,
              flag: this.searchType
            }
          }
      ).then(res => {
        this.isSearch = false
        this.pooledData(res.data)
      })
    },
    changeState(value) {
      console.log(this.editId)
      showConfirmDialog({
        title: '敏感操作 | 修改订单状态',
        message:
            '确认修改订单状态为' + value.text + '吗？',
      })
          .then(() => {
            let choice = -1;
            switch (value.text) {
              //接单
              case this.actions[0].text:
                choice = 1;
                break;
              //取货
              case this.actions[1].text:
                choice = 2;
                break;
              //正在进行
              case this.actions[2].text:
                choice = 3;
                break;
              //即将送回
              case this.actions[3].text:
                choice = 4;
                break;
              //正在送回
              case this.actions[4].text:
                choice = 5;
                break;
              //退回
              case this.actions[5].text:
                choice = 11;
                break;
              default :
                choice = -1;
                break;
            }


            this.$requestServers.post("manager/admin/order/updateState",
                {
                  'uuid':this.editId,
                  'state':choice,
                }
            ).then(res=>{
              this.editId = ''
              if(res.data===200){
                showToast('更新成功');
              }else{
                showToast('未知错误');
              }
            })

          })
          .catch(() => {
            // on cancel
          });


    },




    /**
     * 订单列表预处理
     * @param list
     */
    pooledData(list) {
      //清空列表
      this.orderlist = []
      for (let i = 0; i < list.length; i++) {
        //是第一个数据
        if (i === 0) {
          //创建一个空结点
          this.orderlist.push(
              {
                orderid: list[i].orderid,
                date: list[i].datetime,
                orderdate: list[i].orderdate,
                address: list[i].address,
                state: this.initState(list[i].state),
                msg: []
              }
          )
          //将第一个数据加入列表
          this.orderlist[0].msg.push(list[i])

        }
        //不是第一个数据
        else {
          //是否进行插入操作flag
          let isInsert = false;
          //循环次数等于orderlist当前长度
          for (let j = 0; j < this.orderlist.length; j++) {
            //当订单号与当前orderlist第 j 块 orderid相同
            if (list[i].orderid === this.orderlist[j].orderid) {
              //将该条记录添加进第 j 块 数据中
              this.orderlist[j].msg.push(list[i])
              isInsert = true;
              break;
            }
          }
          //如果未进行插入，即没有orderid与其匹配
          //创建一个新的订单区域
          if (!isInsert) {
            //创建一个空结点
            this.orderlist.push(
                {
                  orderid: list[i].orderid,
                  date: list[i].datetime,
                  orderdate: list[i].orderdate,
                  address: list[i].address,
                  state: this.initState(list[i].state),
                  msg: []
                }
            )
            //将数据加入列表
            this.orderlist[this.orderlist.length - 1].msg.push(list[i])
          }

        }
      }
    },

    initState(value){
      switch (value) {
          //接单
        case 0:
          return '未接单'
          //取货
        case 1:
          return '已接单'
          //正在进行
        case 2:
          return '取货中'
          //即将送回
        case 3:
          return '正在进行'
          //正在送回
        case 4:
          return '即将送回'
          //退回
        case 5:
          return '正在送回'
        case 6:
          return '已完成'
        case 10:
          return '已取消'
        case 11:
          return '已退回'
        default :
          return '未知'
      }
    }


  }
}
</script>

<style scoped>
.search_order_input {
  width: 100%;
  display: flex;
  justify-content: center;
}

.search_order_input input {
  height: 55px;
  width: 90%;
  box-sizing: border-box;
  border: 1px solid black;
  background-color: #1c1c1c00;
  border-radius: 15px;
  padding-left: 20px;
  outline: none;
  margin: 10px 0;
}

.search_order_submit {
  width: 100%;
  display: flex;
  justify-content: center;
}

.search_order_submit input {
  height: 55px;
  width: 90%;
  box-sizing: border-box;
  color: #dedede;
  background-color: #0078f1;
  border: 1px solid #0078f1;
  border-radius: 15px;
  outline: none;
}

.search_order_result {
  width: 96%;
  padding: 2%;
}

.sor_title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 100;
  font-size: 14px;
}

.sor_order_list {
  width: 90%;
  border-radius: 15px;
  padding: 5%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}

.sor_order_list .sol_id {
  font-size: 12px;
  font-weight: 100;
}

.sor_order_list .sol_items {
  border-radius: 12px;
  width: 96%;
  padding: 2%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}
.so_loading{
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>