<template>
  <div class="admin_nav_pc" @mouseenter="nameShowControl(true)" @mouseleave="nameShowControl(false)">
    <div><b>控制台</b></div>
    <div class="admin_nav_pc_options" v-for="items in this.navList" @click="switchTab(items.src)">
      <div>
        <van-icon :name="items.icon" size="25px"/>
      </div>
      <div class="fade-in" v-show="this.showName">&nbsp;{{ items.name }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Admin-Nav-PC",
  data() {
    return {
      showName: false,
      navList: [
        {
          name: '用户管理',
          src: '/private/pc/manage/user',
          icon: 'contact-o',
        },{
          name: '添加用户',
          src: '/private/pc/manage/adduser',
          icon: 'add-o',
        }, {
          name: '订单管理',
          src: '',
          icon: 'todo-list-o',
        }, {
          name: '商品管理',
          src: '',
          icon: 'completed',
        }
      ]
    }
  },
  methods: {
    nameShowControl(value) {
      if (value) {
        setTimeout(() => {
          this.showName = value
        }, 100)
      } else {
        this.showName = value
      }

    },

    switchTab(url) {
      this.$router.push(url)
    }
  }
}
</script>

<style scoped>
.admin_nav_pc {
  box-shadow: 0 0 20px 0 black;
  width: 80px;
  height: 100%;
  background-color: #1c1c1c;
  color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.admin_nav_pc:hover {
  width: 150px;
}

.admin_nav_pc_options {
  width: 80%;
  height: 55px;
  background-color: #1c1c1c;
  box-shadow: 0 0 10px 0 black;
  margin: 20px 0;
  border-radius: 15px;
  transition: all 0.5s;
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin_nav_pc_options:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 0 #b5ffa1,
              0 0 20px 0 #32ff00;
}
</style>