<template>
  <div class="commodityList">
    <div class="block">
      <div class="title">{{name}}</div>
      <br>
      <div class="date" v-if="date"><b>取件时间：{{date}}</b></div>
    </div>
    <div class="block">
      <div>
        <div class="price" v-if="price">
          <span>￥</span>
          <span><b>{{price}}</b></span>
        </div>
      </div>
      <div class="number" v-if="number">
        ×{{number}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Commodity-List",
  props:['name','price','number','date']
}
</script>

<style scoped>
.commodityList{
  width: 90%;
  background-color: #ffffff;
  border-radius: 20px;
  margin: 20px 0;
  padding: 5%;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
.commodityList .block:nth-child(1){
  width: 85%;
}
.commodityList .block:nth-child(2){
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

}
.commodityList .date{
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
}

.commodityList .price span:nth-child(1){
  font-size: 14px;
  color: #d34e14;
}
.commodityList .price span:nth-child(2){
  font-size: 20px;
  color:#ff5e17 ;
}
</style>