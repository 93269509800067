<template>
  <div ref="itemsPad" class="elysova_items_pad fade-in">
    <div class="pad_head">
      <div class="no-select">Elysova Items Pad (V1.0_Beta)</div>
      <div class="pointer" title="关闭" @click="closeTab">
        <img src="@/assets/scene/close.svg" width="70%" height="70%" alt="">
      </div>
    </div>
    <div class="pad_title">
      <span>&nbsp;</span>
      <span>{{title}}</span>
      <span class="discount_msg" v-if="this.discount !== -1 && this.discount"><b>-{{ (10 - this.discount) * 10 }}%</b></span>
    </div>

    <slot/>


  </div>
</template>

<script>
import * as $ from "jquery";

export default {
  name: "Elysova-Items-Pad",
  props:['title','discount'],
  methods: {
    closeTab() {
      $(this.$refs.itemsPad).addClass("fade-out")
      this.$emit('closeTab', false)
    },
  }
}
</script>

<style scoped>
.elysova_items_pad {
  width: 80vw;
  height: 90vh;
  background-color: rgba(238, 237, 237, 0.5);
  backdrop-filter: blur(50px);
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 5vh 10vw;
  overflow-y: scroll;
}

.elysova_items_pad .pad_head {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.pad_head div:nth-child(1) {
  font-size: 10px;
  color: #9d9d9d;
}

.pad_head div:nth-child(2) {
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  transition: background-color 0.5s;
}

.pad_head div:nth-child(2):hover {
  background-color: #f1f1f1;
}
.pad_title {
  font-weight: bolder;
  font-size: 25px;
  margin: 20px 0;
}

.pad_title span:nth-child(1) {
  background-color: #0078f1;
  margin-right: 20px;
}
.pad_title span:nth-child(2) {

}
.pad_title span:nth-child(3) {
  color: #f3f3f3;
  padding: 2%;
  border-radius: 5px;
  background-color: green;
  font-size: 10px;
  margin-left: 20px;
}
</style>