<template>
  <div class="sentry">

    <div class="sentry_loading">
      <van-loading size="50vw" vertical>
        <div class="sentry_text">
          安全检查
        </div>
      </van-loading>
    </div>

  </div>
</template>

<script>
import {showDialog} from "vant";

export default {
  name: "sentry",
  data(){
    return{

    }
  },
  mounted() {

      this.securityCheck()
  },
  methods: {
    securityCheck() {
      //本地不存在名字和key
      if (localStorage.getItem('elysova_sentry_name') === null || localStorage.getItem('elysova_sentry_token') === null) {
        this.$router.push('/security/login', '_blank')
      } else {
        //检查token
        this.$requestServers.post('manager/admin/checkToken',
            {
              'name': localStorage.getItem('elysova_sentry_name'),
              'token': localStorage.getItem('elysova_sentry_token'),
            }
        ).then(res => {
          if(res.data===200){

            //如果orderqp不为空，且订单状态为2，则打开订单快处
            if(this.$route.query.orderqp && this.$route.query.orderstate == 2){
              let query = this.$route.query;
              query['id'] = this.$route.query.orderqp;
              delete query.orderqp
              this.$router.push({ path: '/private/quickprocess', query});
            }else{
              this.$router.push('/security/login', '_blank')
            }


          }else{
            showDialog({
              title: '提示',
              message: '您的登录信息已过期。',
            }).then(() => {
              this.$router.push('/security/login', '_blank')
            });
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.sentry {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sentry_text {
  font-size: 30px;
  font-weight: 900;
}
</style>