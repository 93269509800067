<template>
  <admin-nav/>
  <div class="admin_m_body">
    <div class="admin_m_msg">
      <span>{{ dateMsg }}好~<br></span>
      <span>尊敬的管理员 <span>{{ name }}</span></span>
    </div>
  </div>
</template>

<script>
import adminNav from "@/components/admin/Admin-Nav";

export default {
  name: "admin-mobile",
  components: {
    adminNav
  },
  data() {
    return {
      name: localStorage.getItem('elysova_sentry_name'),
      dateMsg: '晚上',
    }
  },
  mounted() {
    let now = new Date();

    let hours = now.getHours();


    if (hours >= 0 && hours <= 4) {
      this.dateMsg = '凌晨'
    }else if (hours > 4 && hours <= 7) {
      this.dateMsg = '清晨'
    } else if (hours > 7 && hours <= 11) {
      this.dateMsg = '早上'
    } else if (hours > 11 && hours <= 13) {
      this.dateMsg = '中午'
    } else if (hours > 13 && hours <= 16) {
      this.dateMsg = '下午'
    } else if (hours > 16 && hours <= 19) {
      this.dateMsg = '傍晚'
    } else if (hours > 19 && hours < 24) {
      this.dateMsg = '晚上'
    }else{
      this.dateMsg = '晚上'
    }
  }
}
</script>

<style scoped>
.admin_m_body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin_m_msg{
  margin-top: 30%;
  font-weight: 100;
}
.admin_m_msg span:nth-child(1){
  font-size: 25px;
}
.admin_m_msg span:nth-child(2){
  font-size: 15px;
}
.admin_m_msg span:nth-child(2) span{
  color: #36b2ff;
  font-size: 25px;
  font-weight: 900;
}
</style>