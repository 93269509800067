<template>
  <div class="admin_login no-select">
    <div class="al_body ">
      <div class="alb_title">
        <span>Login</span>
        <span> | Elysova Sentry.</span>
      </div>
      <div class="i_title">
        账号
      </div>
      <div class="alb_input">
        <input type="text" maxlength="20" v-model="account">
      </div>
      <div class="i_title">
        密码
      </div>
      <div class="alb_input">
        <input type="password" maxlength="20" v-model="password">
      </div>
      <div class="alb_input">
        <input type="button" value="认证" @click="login">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "adminLogin",

  data() {
    return {
      account: '',
      password: '',
      failCount: 0,

      redirect : this.$route.query.redirectURL
    }
  },
  mounted() {
    //信息制空
    localStorage.setItem('elysova_sentry_name','')
    localStorage.setItem('elysova_sentry_token','')
  },
  methods: {
    login() {
      if (this.failCount <= 5) {
        this.$requestServers.post("manager/admin/login",
            {
              'name': this.account,
              'password': this.password,
            }
        ).then(res => {
          localStorage.setItem('elysova_sentry_name',this.account)
          localStorage.setItem('elysova_sentry_token',res.data)
          this.$router.push('/private/pad')
        })
      }
    }
  }
}
</script>

<style scoped>
.admin_login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1c1c1c;
  color: #dedede;
}

.al_body {
  width: 80%;
  padding: 5%;
  box-shadow: 0 0 20px 0 black;
  background-color: #1c1c1c;
  border-radius: 15px;
}

.al_body .alb_title {
  margin: 20px 0;
}

.al_body .alb_title span:nth-child(1) {
  font-size: 25px;
  font-weight: 900;
}

.al_body .alb_title span:nth-child(2) {
  font-size: 12px;
}

.al_body .i_title {
  margin: 10px 0;
}

.al_body .alb_input {
  height: 50px;
  margin-bottom: 20px;
}

.al_body .alb_input input {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid #dedede;
  background-color: #00000000;
  box-sizing: border-box;
  padding-left: 5%;
}

.al_body .alb_input:last-child input {
  margin-top: 20px;
  background-color: #0078f1;
  border: 1px solid #0078f1;
}

.al_body .alb_input:last-child input:hover {
  animation: btn_click_mobile 0.5s;
}

@keyframes btn_click_mobile {
  0% {
    background-color: #0078f1;
  }
  30% {
    background-color: #00000000;
  }
  100% {
    background-color: #0078f1;
  }
}
</style>