<template>
  <div class="admin_pc_hello">
    <div>
      <span>Welcome to Administrator Dashboard.</span>
    </div>
    <div>
      <span>Powered by AnyaJoey</span>&nbsp;|&nbsp;
      <span>Elysova Technology</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "admin-pc-hello"
}
</script>

<style scoped>
  .admin_pc_hello{
    padding: 2%;
    width: 96%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    margin-top: -5vh;
  }
  .admin_pc_hello div:nth-child(1) span{
    font-size: 40px;
    font-weight: 900;
    background: -webkit-linear-gradient(265deg,#42d392 25%,#647eff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .admin_pc_hello div:nth-child(2){
    margin-top: 40px;
  }
  .admin_pc_hello div:nth-child(2) span:nth-child(1){
    font-size: 20px;
    color: #fafafa;
  }
  .admin_pc_hello div:nth-child(2) span:nth-child(2){
    font-size: 20px;
    background: -webkit-linear-gradient(315deg, #e432ff 25%, #4069ff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>