<template>
  <div class="admin_pc">
    <div class="admin_pc_left">
      <admin-nav-pc/>
    </div>
    <div class="admin_pc_right">
      <router-view/>
    </div>
  </div>
</template>

<script>
import 'element-plus/theme-chalk/dark/css-vars.css'
import adminNavPc from '@/components/admin/Admin-Nav-PC'
export default {
  name: "admin-pc",
  components:{
    adminNavPc,
  },
  mounted() {
    this.$router.push('/private/pc/hello')
  }
}
</script>

<style scoped>
.admin_pc{
  width: 100%;
  height: 100%;
  background-color: #1c1c1c;
  color: white;
  display: flex;
  justify-content: left;
  overflow: hidden;
}
.admin_pc .admin_pc_left{
  width:  auto;
  height: 100%;
}
.admin_pc .admin_pc_right{
  width: 90%;
  height: 100%;
}
</style>